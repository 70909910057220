import { _createService } from '../../hooks';
import { responsive } from './responsive';
import './style.css';
import { Buttons } from '../../fragments';
import { useState } from 'react';
import { toast } from 'react-toastify';

const logo = require('../../assets/images/logo.png');

const helper = {
    'discord': {
        'guildID': {
            title: 'How to retreive your discord Guild ID:',
            description: 'On discord go to User Settings, advanced and turn on "Developer Mode"',
            img: require('../../assets/images/DiscordGuildId.jpg'),
            description2:'Then right click on your server and copy ID',
        },
        'roleID': {
            title: 'How to retreive you RoleID',
            description: 'On discord go to your Server Settings, Roles.',
            img: require('../../assets/images/roleId.jpg'),
            description2: 'Then right click on the role and copy ID.',
        }
    },
    'telegram': {
        'guildID': {
            title: 'How to retreive your telegram group ID:',
            description: `Once the bot in your group simply send /groupe in the group chat.`,
            img: require('../../assets/images/telegramGuildId.jpg'),
            description2: 'Then copy the id (remove subtraction sign).',
        },
        'roleID': {
            title: 'How to retreive you RoleID',
            description: 'Blah blah blah',
        }
    }
};

const Form = ({
    service,
    help,
    setHelp,
    newServiceId,
    setNewServiceId,
    dynamicStyle,
    onSubmitService,
    onAbort,
}) => (
    <div className='content-area'>
        <h1>Configure a <span style={{color:'#00ffff'}}>{service}</span> service !</h1>
        <p  style={{marginBottom: '5%'}}>Now that your guild is added to our contract, you need to attach a service our bot will operate on. Choose a service in the following menu and add the requested informations.</p>
        <input name='serviceId' id='serviceId' type='text' placeholder='Guild ID' value={newServiceId} onChange={(input) => setNewServiceId(input.target.value)}/>
        <p>This is the ID of your {service} guild. (<span className='helper' onClick={() => help === false ? setHelp('guildID') : setHelp(false)}>Learn More</span>)</p>
        <p style={{marginTop: '5%'}}>Please do not use special characters (only utf-8) in the form fields. Also make sure you have enough founds to run the transaction.<br /><br/>Do not send funds to the smart contract affiliated wallets, all funds will be lost.</p>
        <div className='buttons-area'>
            <Buttons 
                borderColor='#00ffff'
                backgroundColor='#07073d'
                height={dynamicStyle.button.height}
                width={dynamicStyle.button.width}
                colorText='#00ffff'
                text={'Abort'}
                link={() => onAbort()}
                marginRight={dynamicStyle.button.marginRight}
                fontSize={dynamicStyle.button.fontSize}
            />
            <Buttons 
                borderColor='#00ffff'
                backgroundColor='#07073d'
                height={dynamicStyle.button.height}
                width={dynamicStyle.button.width}
                colorText='#00ffff'
                text={'Create Service'}
                link={() => onSubmitService()}
                marginRight={dynamicStyle.button.marginRight}
                fontSize={dynamicStyle.button.fontSize}
            />
        </div>
    </div>
);

const Success = ({step, newGuild}) => (
    <>
        { step === 1 ? (
            <img className='logo-img' src={logo} alt='Logo Br0bot' />
        ) : (
            <>
                <h2>You successfully created your first Guild !</h2>
                <p>Tx: <a href={`https://snowtrace.com/tx/` + newGuild.hash} target='_blank' rel="noreferrer">{newGuild.hash}</a></p>
                <p>GuildSpace: <a href={`https://br0bot.com/space/${newGuild.guildId}`} target='_blank' rel="noreferrer">{`https://br0bot.com/space/${newGuild.guildId}`}</a></p>
            </>
        ) }
    </>
);

const Helper = ({help, service, setImageViewer}) => (
    <>
        <h2>{ helper[service][help].title }</h2>
        <p>{ helper[service][help].description }</p>
        <img onClick={() => setImageViewer(helper[service][help].img)} className='logo-discordguildId' src={helper[service][help].img} alt='discord developer helper' />
        {helper[service][help].description2 ? <p>{ helper[service][help].description2 }</p> : null}
        {service === "discord" ? (
            <a href={`https://support.discord.com/hc/en-us/articles/206346498-Where-can-I-find-my-User-Server-Message-ID-#:~:text=Obtaining%20Server%20IDs%20%2D%20Mobile%20App,name%20and%20select%20Copy%20ID.`} target='_blank' rel="noreferrer">More help here</a>
        ) : null}
    </>
);

const Side = ({step, help, service, newGuild, newService, setImageViewer}) => {
    const marginContent = {
        marginTop: step >= 2 ? '25%' : '20%',
        marginRight: step >= 2 ? '5%' : '20%',
        marginLeft: step >= 2 ? '5%' : '20%', 
    };
    return (
        <div className='logo-area' style={marginContent}>
            { help === false ? (
                <Success 
                    step={step}
                    service={service}
                    newGuild={newGuild}
                    newService={newService}
                />
            ) : (
                <Helper 
                    help={help}
                    service={service}
                    setImageViewer={setImageViewer}
                />
            ) }
        </div>
    );
};

export const CreateService = ({format, service, spaceId, setCreateService}) => {
    const dynamicStyle = responsive[format];

    const [help, setHelp] = useState(false);

    const [newServiceId, setNewServiceId] = useState('');

    const [newService, setnewService] = useState({});

    const [imageViewer, setImageViewer] = useState('');

    const onSubmitService = async () => {
        let serviceID = 0;
        const loading = toast.loading("Creating new service ...", { position: toast.POSITION.TOP_LEFT });
        if (newServiceId.startsWith('-')) {
            serviceID = newServiceId.substring(1)
        } else serviceID = newServiceId
    
        const receipt = await _createService(spaceId,service,serviceID);
        if (receipt.success) {
            toast.update(loading, { 
                render: receipt.message,
                type: "success",
                isLoading: false,
                position: toast.POSITION.TOP_LEFT,
                autoClose: 3000, 
            });
            setnewService(receipt);
            setHelp(false);
            onAbort();
        } else {
            toast.update(loading, { 
                render: receipt.message,
                type: "error", isLoading: false,
                position: toast.POSITION.TOP_LEFT,
                autoClose: 3000,
            });
        };
    };

    const onAbort = () => {
        setCreateService(false);
        window.location.reload();
    };

    return(
        <div className='createGuild'>
            { imageViewer !== "" ? (
                <div className='image-viewer'>
                    <img className='image-viewer' src={imageViewer} alt='imageViewer' />
                    <button className='close-imageViewer' onClick={() => setImageViewer('')}>Close</button>
                </div>
            ) : null

            }
            <div className='leftRow'>
                <Form 
                    service={service}
                    help={help}
                    setHelp={setHelp}
                    newServiceId={newServiceId}
                    setNewServiceId={setNewServiceId}
                    dynamicStyle={dynamicStyle}
                    onSubmitService={onSubmitService}
                    onAbort={onAbort}
                />
            </div>
            <div className='rightRow'>
                <Side 
                    service={service}
                    help={help}
                    newGuild={spaceId}
                    newService={newService}
                    setImageViewer={setImageViewer}
                />
            </div>
        </div>
    );
};