import { ethers } from 'ethers';
import { isEmpty } from 'lodash';

const artifacts = require('../../artifacts/Membership.json');
const ContractAdress = "0x9ED916f7Dc549C821A26675c958BB85061be2ef6";

const AVALANCHE_MAINNET_PARAMS = {
    chainId: '0xa86a',
    chainName: 'Avalanche Mainnet C-Chain',
    nativeCurrency: {
      name: 'Avalanche',
      symbol: 'AVAX',
      decimals: 18
    },
    rpcUrls: ['https://api.avax.network/ext/bc/C/rpc'],
    blockExplorerUrls: ['https://snowtrace.io/']
};
/*
const AVALANCHE_TESTNET_PARAMS = {
    chainId: '0xa869',
    chainName: 'Avalanche FUJI C-Chain',
    nativeCurrency: {
      name: 'Avalanche',
      symbol: 'AVAX',
      decimals: 18
    },
    rpcUrls: ['https://api.avax-test.network/ext/bc/C/rpc'],
    blockExplorerUrls: ['https://testnet.snowtrace.io/']
};
*/
let contractPayables = null;
const chooseprovider = () => {
    let provider = new ethers.providers.JsonRpcProvider("https://avalanche-mainnet.infura.io/v3/03a857ac507940bda7986f01a5d72998");
    try {
        provider = new ethers.providers.Web3Provider(window.ethereum, "any");
        const signer = provider.getSigner();
        contractPayables =  new ethers.Contract(ContractAdress, artifacts.abi, signer);
    } catch {
        console.log("no web wallet");
    };
    return provider;
};
const provider = chooseprovider();

const contractViews =  new ethers.Contract(ContractAdress, artifacts.abi, provider);

if (typeof window.ethereum !== 'undefined') {
    window.ethereum.on('accountsChanged', () => {
        if (window.location.pathname.includes("space")) {
            window.location.reload();
        };
    });
    window.ethereum.on('chainChanged', (chain) => {
        if (chain !== AVALANCHE_MAINNET_PARAMS.chainId) {
            window.location.href = "/";
        };
    });
};
export const _getAccount = async () => {  
    let account = false;
    if (provider.anyNetwork) {
        const acnt = await provider.listAccounts();
        if (acnt.length) account = acnt[0];
    };
    return account;
};
export const _getMember = async (memberId) => {
    let member = false;
    if (provider !== null) {
        const memberData = await contractViews.getMember(memberId);
        if (memberData[0] !== "" && memberData[1] !== 0 && memberData[2] !== 0) {
            member = memberData[0];
        };
    }
    return member;
};
const connectMetaMask = async () => {
    if (window.ethereum) {
        try {
            let account = await window.ethereum.request({ method: 'eth_requestAccounts' });
            return account;
        } catch (err) {
            return err.code
        }
        
    } else return "noprovider";
};

const switchToAvalancheChain = async (network) => {  
    await window.ethereum.request({method: 'wallet_addEthereumChain', params: [AVALANCHE_MAINNET_PARAMS]});
    const newNetwork = await provider.getNetwork();
    if (newNetwork.chainId === 43114 || newNetwork.chainId === "0xa86a") {
        return true;
    } else return false;
}
export const checkChain  = async () => { 
    let chain = false;
    if (provider.anyNetwork) {
        const network  = await provider.getNetwork();
        if (network.chainId === 43114 || network.chainId === "0xa86a") {
            chain = true;
        } else {
            chain = await switchToAvalancheChain(network);
        };
    } else chain = true;
    return chain;
};
export const checkProvider = async () => { 
    let account = await _getAccount();
    let chain = false;
    if (!account) {
        account = await connectMetaMask();  
    };
    if (provider.anyNetwork) {
        const network  = await provider.getNetwork();
        if (network.chainId === 43114 || network.chainId === "0xa86a") {
            chain = true;
        } else {
            chain = await switchToAvalancheChain(network);
        };
    } else chain = true;
    return {account, chain};
};
export const _createGuild = async (name,description,location,logo) => {
    try { 
        const gasLimit = await contractPayables.estimateGas.createGuild(name, description, location, logo);
        const tx = await contractPayables.createGuild(name, description, location, logo, {gasLimit: gasLimit});
        const receipt = await tx.wait();
        if (receipt.status === 1) {
            const events = receipt.events
            const event = events.find(event => event.event === 'Guild');
            const guild = {
                guildId: event.args.guild,
                hash: event.transactionHash,
                success: true,
                message: "Guild successfully created !",
            }
            return guild;
        } else return {success: false, message: "Transaction failed"};
    }
    catch(error) {
        if (error.code === "ACTION_REJECTED") return {success: false, message: "Transaction canceled"};
        return {success: false, message: "Something went wrong !"}
    }; 
};

export const _createService = async (guildId,service,serviceIdentifier) => {
    try {
        const gasLimit = await contractPayables.estimateGas.createService(guildId, service, "not settle", serviceIdentifier);
        const tx = await contractPayables.createService(guildId,service,"not settle",serviceIdentifier, {gasLimit: gasLimit});
        const receipt = await tx.wait();
        if (receipt.status === 1) {
            const events = receipt.events
            const event = events.find(event => event.event === 'Service');
            const serviceId = {
                guildId: event.args.guild,
                serviceId: event.args.service,
                hash: event.transactionHash,
                success: true,
                message: "Service successfully created !"
            };
            return serviceId;
        } else return {success: false, message: "Transaction failed"};
        
    } catch(error) {
        if (error.code === "ACTION_REJECTED") return {success: false, message: "Transaction canceled"};
        return {success: false, message: "Something went wrong !"};
    };
};

export const _createPlan = async (guildPlan,Planservice,name,Plandesc,price,duration,until,role) => {
    try {
        const gasLimit = await contractPayables.estimateGas.createPlan(guildPlan,Planservice,name,Plandesc,price,duration,until,role);
        const tx = await contractPayables.createPlan(guildPlan,Planservice,name,Plandesc,price,duration,until,role, {gasLimit: gasLimit});
        const receipt = await tx.wait();
        if (receipt.status === 1) return {success: true, message:`Plan ${name} successfully created !`};
        return {success: false, message: "Transaction failed"};
    } catch(error) {
        if (error.code === "ACTION_REJECTED") return {success: false, message: "Transaction canceled"};
        return {success: false, message: "Something went wrong !"};
    };
};
export const _createEvent = async (guild, service, name, description, banner, price, start, end, role) => {
    try {
        const gasLimit = await contractPayables.estimateGas.createEvent(guild, service, name, description, banner, price, start, end, role);
        const tx = await contractPayables.createEvent(guild, service, name, description, banner, price, start, end, role, {gasLimit: gasLimit});
        const receipt = await tx.wait();
        if (receipt.status === 1) return {success: true, message:"Event successfully created !"};
        return {success: false, message: "Transaction failed"};
    } catch(error) {
        if (error.code === "ACTION_REJECTED") return {success: false, message: "Transaction canceled"};
        return {success: false, message: "Something went wrong !"};
    };
};
export const _getGuild = async(spaceId) => {
    const emptyAddress = "0x0000000000000000000000000000000000000000";
    let guild = {
        name: false,
    };
    if (spaceId !== 0 && contractViews !== false) {
        const guildinfo = await contractViews.getGuild(spaceId);
        if (!(guildinfo[0] === "" 
            && guildinfo[1] === "" 
            && guildinfo[2] === "" 
            && guildinfo[3] === ""
            && guildinfo[4] === emptyAddress 
            && guildinfo[5] === 0 
            && guildinfo[6] === 0
        )) {
            guild = {
                name: guildinfo[0],
                description: guildinfo[1],
                continent: guildinfo[2],
                logo: guildinfo[3],
                owner: guildinfo[4],
                created: guildinfo[5],
                verified: guildinfo[6],
            };
        };  
    };
    return guild;
};
const _getServices = async (spaceId) => {
    const services = await contractViews.getGuildsServices(spaceId);
    const servicesList = [];
    for (let i = 0; i < services.length; i++) {
        const serviceObj = await _getService(services[i]);
        servicesList.push(serviceObj);
    }
    return servicesList;
};
const _getService = async (service) => {
    let serviceObj = {
        mode: false,
    };
    if (service > 0) {
        const serviceInfo = await contractViews.getService(service);
        serviceObj = {
            mode: serviceInfo[0],
            description: serviceInfo[1],
            identifier: serviceInfo[2],
            created: serviceInfo[3],
            id: service,
        };
    };
    return serviceObj;
};
const _getPlans = async (serviceId) => {
    const plans = await contractViews.getServicesPlans(serviceId);
    if (plans.length === 0) return false;
    const planList = [];
    for (let i = 0; i < plans.length; i++) {
        if (plans[i] > 0) {
            const planInfo = await contractViews.getPlan(plans[i]);
            const weiPrice = await contractViews.conversionRate(planInfo[2]);
            let avaxPrice = 0;
            avaxPrice = (weiPrice * Math.pow(10, -18) * 1.01);
            avaxPrice = avaxPrice * Math.pow(10,8);
            avaxPrice = Math.trunc(avaxPrice);
            avaxPrice = avaxPrice / Math.pow(10,8);
            const planObj = {
                name: planInfo[0],
                description: planInfo[1],
                price: planInfo[2],
                duration: planInfo[3],
                role: planInfo[5],
                avax: avaxPrice,
                id: plans[i],
            }
            planList.push(planObj);
        }
    }
    return planList;
};
export const _avaxPrice = async (price) => {
    let weiPrice = await contractViews.conversionRate(price);
    return weiPrice;
};
const _getEvents = async (serviceId) => {
    const events = await contractViews.getServicesEvents(serviceId);
    if (events.length === 0) return false;
    const eventList = [];
    for (let i = 0; i < events.length; i++) {
        if (events[i] > 0) {
            const eventInfo = await contractViews.getEvents(events[i]);
            const weiPrice = await _avaxPrice(eventInfo[3]);
            let avaxPrice = (weiPrice * Math.pow(10, -18) * 1.01);
            avaxPrice = avaxPrice * Math.pow(10,8);
            avaxPrice = Math.trunc(avaxPrice);
            avaxPrice = avaxPrice / Math.pow(10,8);
            const planObj = {
                name: eventInfo[0],
                description: eventInfo[1],
                banner: eventInfo[2],
                price: eventInfo[3],
                start: eventInfo[4],
                end: eventInfo[5],
                role: events[6],
                id: events[i],
                avax: avaxPrice,
            }
            eventList.push(planObj);
        }
    }
    return eventList;
};
export const _getSpaceContent = async (spaceId) => {
    const spaceContent = {
        guild: false,
        service: false,
    };
    if (spaceId !== 0 && provider !== null) {
        try  {
            const guildData = await _getGuild(spaceId);
            if (guildData.name !== "") {
                let finalServices = {};
                spaceContent.guild = guildData;
                spaceContent.connect = true;
                if (guildData !== false) {
                    const serviceData = await _getServices(spaceId);
                    if (serviceData !== false && serviceData.length > 0) {
                        for (let i = 0; i < serviceData.length; i++) {
                            const planData = await _getPlans(serviceData[i].id);
                            const eventData = await _getEvents(serviceData[i].id);
                            finalServices = {...finalServices,  [serviceData[i].mode]: {
                                serviceID: serviceData[i].id,
                                plans: planData,
                                events: eventData,
                            }};
                        }
                    };
                    spaceContent.service = !isEmpty(finalServices) ? finalServices : false;
                };
            };
        } catch {
            return {guild: "error"};
        }
    };
    return spaceContent;
}; 
export const _register = async (username) => {
    let status = {
        success: false,
        message: "Something went wrong",
    };
    if (username !== "") {
        try {
            const gasLimit = await contractPayables.estimateGas.register(username);
            const tx = await contractPayables.register(username, {gasLimit: gasLimit});
            const receipt = await tx.wait();
            if (receipt.status === 1) return {success: true, message: `Successfully registered ${username}`};
            return status;
        } catch (error) {
            if (error.code === "ACTION_REJECTED") return status = {success: false, message: "Transaction canceled"};
            return status;
        }
    } else {
        return status = {success: false, message: "Username can't be empty !"}
    }
};
export const _checkRegister = async (wallet) => {
    let register = {
        account: false,
        register: false,
    };
    if (wallet !== "") {
       const memberId = await contractViews.getMemberFromWallet(wallet);
       if (memberId !== 0) register = {account: true, register: memberId};
    };
    return register;
};
export const _join = async (guild, service, accountId) => {
    let status = {
        success: false,
        message: "Something went wrong",
    };
    if (guild !== 0 && service !== 0 && accountId !== 0 && accountId !== "") {
        try {
            const gasLimit = await contractPayables.estimateGas.join(guild, service, accountId);
            const tx = await contractPayables.join(guild, service, accountId, {gasLimit: gasLimit});
            const receipt = await tx.wait();
            if (receipt.status === 1) return {success: true, message: "Successfully joined !"};
            return status;
        } catch (error) {
            if (error.code === "ACTION_REJECTED") return {success: false, message: "Transaction canceled"};
            return status;
        };
    };
    return status;
};
export const _checkJoin = async (wallet, service) => {
    let join = false; 
    if (wallet !== "" && service !== 0 && service !== "") {
        const member = await _checkRegister();
        if (member !== false) {
            const memberInService = await contractViews.getMemberAccount(member, service);
            if (memberInService !== 0) {
                join = true;
            };
        };
    };
    return join;
};
export const _getMembersFromGuildService = async (service) => {
    let members = [];
    const guildMembers = await contractViews.getMembersFromGuildService(service);
    if (guildMembers.length > 0) members = guildMembers;
    return members;
};
export const _subscribe = async (guild, service, plan) => {
    let status = {
        success: false,
        message: "Something went wrong",
    };
    if (provider !== null) {
        const account = await _getAccount();
        const memberId = await contractViews.getMemberFromWallet(account);
        const balance = await provider.getBalance(account);

        const planInfo = await contractViews.getPlan(plan);
        const price = planInfo[2];
        const role = planInfo[5];
        const weiPrice = await _avaxPrice(price);

        if (ethers.utils.formatEther(weiPrice) < ethers.utils.formatEther(balance)) {
            try {
                let gasLimit = 500000;
                try {
                    gasLimit = await contractPayables.estimateGas.subscribe(guild, service, plan, {value: weiPrice});
                } catch {
                    return {success: false, message: "you need to register and join to perform this action"}
                }
                const tx = await contractPayables.subscribe(guild, service, plan, {value: weiPrice,gasLimit: gasLimit});
                const receipt = await tx.wait();
                if (receipt.status === 1) return status = {success: true, message: "Subscribe success !", memberId: memberId, role: role};
                return status;
            } catch (error) {
                if (error.code === "ACTION_REJECTED") return status = {success: false, message: "Transaction canceled"};
                console.log(error);
                return status;
            }
        } else {
            return status = {success: false, message: "Not enough avax to subscribe !"};  
        };
    };
    return status;
};
export const _participate = async (guild, service, event) => {
    let status = {
        success: false,
        message: "Something went wrong",
    };
    if (provider !== null) {
        const account = await _getAccount();
        const memberId = await contractViews.getMemberFromWallet(account);
        const balance = await provider.getBalance(account);

        const eventInfo = await contractViews.getEvents(event);
        const price = eventInfo[3];
        const role = eventInfo[6];
        const weiPrice = await _avaxPrice(price);

        if (ethers.utils.formatEther(weiPrice) < ethers.utils.formatEther(balance)) {
            try {
                const gasLimit = await contractPayables.estimateGas.participate(guild, service, event, {value: weiPrice});
                const tx = await contractPayables.participate(guild, service, event, {value: weiPrice,gasLimit: gasLimit});
                const receipt = await tx.wait();
                if (receipt.status === 1) return status = {success: true, message: "Enjoy your event !"};
                return status;
            } catch (error) {
                if (error.code === "ACTION_REJECTED") return status = {success: false, message: "Transaction canceled"};
                return status;
            }
        } else {
            return status = {success: false, message: "Not enough avax to participate !"};
        };
    };
    return status;
};
export const _getOwnedGuild = async (account) => {
    if (account) {
        const ownedGuild = await contractViews.getGuildsFromCreator(account);
        return ownedGuild;
    } else {
        return false;
    }
}
export const _checkIfOwner = async (spaceId) => {
    const account = await _getAccount();
    if (account) {
        const ownedGuild = await _getOwnedGuild(account);
        if (ownedGuild !== false && ownedGuild.length > 0) {
            for (let i = 0; i < ownedGuild.length; i++) {
                if (ownedGuild[i].toString() === spaceId) {
                    return true;
                };  
            };
        };
    };
    return false;
};
export const _getAverageFee = async () => {
    let gasPrice = 0;
    if (provider.anyNetwork) {
        const network = await provider.getNetwork();
        if (network.chainId === 43114 || network.chainId === "0xa86a") {
            gasPrice = await provider.getFeeData();
            const gasLimit = await contractPayables.estimateGas.join(1, 1, 7444444);
            gasPrice = gasPrice.gasPrice * gasLimit; 
            gasPrice = ethers.utils.formatEther(gasPrice);
        };
    };
    return gasPrice;
};
export const _getUserSubs = async (spaceId) => {
    const date = new Date();
    const time = Math.round(date.getTime() / 1000);
    let activeSubs = [];

    const account = await _getAccount();
    if (account) {
        const memberId = await contractViews.getMemberFromWallet(account);
        if (memberId === 0) return activeSubs;
    
    
        const guildServices = await contractViews.getGuildsServices(spaceId);
        for (let i = 0; i < guildServices.length; i++) {
            if (guildServices[i] > 0) {
                const subs = await contractViews.getMembersSubscriptions(guildServices[i], memberId);
                for (let i = 0; i < subs.length; i++) {
                    const sub = await contractViews.getSubscriptions(subs[i]);
                    if (time < sub[2]) {
                        activeSubs.push(sub[0]);
                    };
                };
            };
        };
    };
    return activeSubs;
};
export const _getUserEvents = async (spaceId) => {
    const date = new Date();
    const time = Math.round(date.getTime() / 1000);
    let activeEventSubs = [];
    
    const account = await _getAccount();
    if (account) {
        const memberId = await contractViews.getMemberFromWallet(account);
        if (memberId === 0) return activeEventSubs;
    
        const discordService = await contractViews.findGuildService(spaceId, "discord");
        if (discordService === 0) return activeEventSubs;
    
        const serviceEvents = await contractViews.getServicesEvents(discordService);
        if (serviceEvents === 0) return activeEventSubs;
    
        for (let i = 0; i < serviceEvents.length; i++) {
            if (serviceEvents[i] > 0) {
                const event = await contractViews.getEvents(serviceEvents[i]);
                if (time < event[5]) {
                    const attendies = await contractViews.getEventAttendies(discordService,serviceEvents[i]);
                    if (attendies.includes(memberId)) {activeEventSubs.push(serviceEvents[i])};
                };   
            };
        };
    };
    return activeEventSubs;
};
export const _deletePlan = async (guild, service, id) => {
    try {
        const gasLimit = await contractPayables.estimateGas.deletePlan(guild, service, id);
        const tx = await contractPayables.deletePlan(guild, service, id, {gasLimit: gasLimit});
        const receipt = await tx.wait();
        if (receipt.status === 1) return {success: true, message: "Plan deleted, may take some time before disappearing on website"};
        return {success: false, message: "Transaction failed"};
    } catch (error) {
        if (error.code === "ACTION_REJECTED") return  {success: false, message: "Transaction canceled"};
        return {success: false, message: "Something went wrong !"};
    }
    
};
export const _deleteEvent = async (guild, service, id) => {
    try {
        const gasLimit = await contractPayables.estimateGas.deletePlan(guild, service, id);
        const tx = await contractPayables.deleteEvent(guild, service, id, {gasLimit: gasLimit});
        const receipt = await tx.wait();
        if (receipt.status === 1) return {success: true, message: "Plan deleted, may take some time before disappearing on website"};
        return {success: false, message: "Transaction failed"};
    } catch (error) {
        if (error.code === "ACTION_REJECTED") return {success: false, message: "Transaction canceled"};
        return {success: false, message: "Something went wrong !"};
    }
};
export const _getSubsStats = async (serviceName,service, members, timestamp, plans) => {
    let subsStats = {
        service: serviceName,
        stats: {},
        earned: {},
    };
    let planStats = {};
    for (let i = 0; i < plans.length; i++) {
        const plan = await contractViews.getPlan(plans[i].id);
        planStats = {...planStats,  [plans[i].id]: {
            name: plan[0],
            price: Number(plan[2]),
            planSubscribers: {
                january: 0,
                february: 0,
                march: 0,
                april: 0,
                may: 0,
                june: 0,
                july: 0,
                august: 0,
                september: 0,
                october: 0,
                november: 0,
                december: 0,
            },
        }};
    };
    subsStats.stats = planStats;
    for (let i = 0; i < members.length; i++) {
        if (members[i] > 0) {
            const subs = await contractViews.getMembersSubscriptions(service, members[i]);
            for (let i = 0; i < subs.length; i++) {
                if (subs[i] > 0) {
                    const sub = await contractViews.getSubscriptions(subs[i]);
                    for (let i = 0; i < timestamp.length; i++) {
                        if (!(sub[1]*1000 < timestamp[i].start)) {
                            if (timestamp[i].start < sub[1]*1000 && sub[1]*1000 < timestamp[i].end) {
                                subsStats.stats[sub[0]].planSubscribers[timestamp[i].month] = subsStats.stats[sub[0]].planSubscribers[timestamp[i].month] + 1;
                                break;
                            };
                        };  
                    };
                };
            };
        };
    };
    let earnedFinal = {
        january: 0,
        february: 0,
        march: 0,
        april: 0,
        may: 0,
        june: 0,
        july: 0,
        august: 0,
        september: 0,
        october: 0,
        november: 0,
        december: 0
    };
    for (const property in subsStats.stats) {
        for (const month in subsStats.stats[property].planSubscribers) {
            earnedFinal[month] = earnedFinal[month] + subsStats.stats[property].price * subsStats.stats[property].planSubscribers[month];
        }
    }
    subsStats.earned = earnedFinal;
    return subsStats;
};
