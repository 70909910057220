import { Header, BulletPoints, Storyline, Footer, Faq } from '../../components';
export const Home = ({format}) => {
    return(
        <>
            <Header format={format} />
            <BulletPoints format={format} />
            <Storyline format={format} />
            <Faq format={format} />
            <Footer format={format} />   
        </>
    );
};