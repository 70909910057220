export const responsive = {
    'small': {
        button: {
            height: '40px',
            width: '150px',
            marginRight: '5%',
            fontSize: '0.9rem',
        },
        title: {
            fontSize: '1.5rem',
        },
        leftRow: {
            display: 'flex',
            width: '96%',
        },
        rightRow: {
            display: 'none',
            width: '96%',
        },
    },
    'medium': {
        button: {
            height: '40px',
            width: '150px',
            marginRight: '5%',
            fontSize: '1rem',
        },
        title: {
            fontSize: '2rem',
        },
        leftRow: {
            display: 'flex',
            width: '50%',
        },
        rightRow: {
            display: 'flex',
            width: '50%',
        },
    },
    'large': {
        button: {
            height: '50px',
            width: '200px',
            marginRight: '5%',
            fontSize: '1.2rem',
        },
        title: {
            fontSize: '2.5rem',
        },
        leftRow: {
            display: 'flex',
            width: '50%',
        },
        rightRow: {
            display: 'flex',
            width: '50%',
        },
    },
    'x-large': {
        button: {
            height: '50px',
            width: '200px',
            marginRight: '5%',
            fontSize: '1.2rem',
        },
        title: {
            fontSize: '2.5rem',
        },
        leftRow: {
            display: 'flex',
            width: '50%',
        },
        rightRow: {
            display: 'flex',
            width: '50%',
        },
    },
    'xx-large': {
        button: {
            height: '60px',
            width: '250px',
            marginRight: '5%',
            fontSize: '1.3rem',
        },
        title: {
            fontSize: '2.5rem',
        },
        leftRow: {
            display: 'flex',
            width: '50%',
        },
        rightRow: {
            display: 'flex',
            width: '50%',
        },
    },
    'xxx-large': {
        button: {
            height: '60px',
            width: '250px',
            marginRight: '5%',
            fontSize: '1.4rem',
        },
        title: {
            fontSize: '2.5rem',
        },
        leftRow: {
            display: 'flex',
            width: '50%',
        },
        rightRow: {
            display: 'flex',
            width: '50%',
        },
    }
};