import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import './style.css';
import { WindowController } from "../WindowController";

export const MainController = () => {
    return(
        <Router>
            <div className='wrapper'>
                <WindowController/>
            </div>
        </Router>
    );
};