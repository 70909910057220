import { useState, useEffect } from "react";

const windowSizes = [
    {
        name: 'small',
        max: 768,
    },
    {
        name: 'medium',
        max: 1024,
    },
    {
        name: 'large',
        max: 1224
    },
    {
        name:'x-large',
        max: 1824,
    },
    {
        name: 'xx-large',
        max: 2464,
    },
    {
        name: 'xxx-large',
        max: 3458,
    }
];

const isBetween = (width, max, nextMax = false) => {
    if(!nextMax
        ? width >= 0 && width < max 
        : width >= max && width < nextMax
    ) {
        return true
    }
    return false;
}
export const useDefaultFormat = (width) => {
    const maxInterval = windowSizes.length - 1;

    for (let key = 0; key < windowSizes.length; key++) {
        const formatName = windowSizes[key].name;
        const formatMax = windowSizes[key].max;
        if(key !== maxInterval) {
            const formatNextMax = windowSizes[key + 1].max;
            if(key === 0 
                ? isBetween(width, formatMax)
                : isBetween(width, formatMax, formatNextMax)
            ) {
                return formatName;
            }
        } else {
            if(width > windowSizes[key].max) {
                return formatName;
            }
        }
    }
    
}
export const useResponsiveFormat = (defaultFormat, width) => {
    const maxInterval = windowSizes.length - 1;

    const [lastWidth, setWidth] = useState(width);
    const [format, setFormat] = useState(defaultFormat);

    useEffect(() => {
        if(!format || width !== lastWidth) {
            for (let key = 0; key < windowSizes.length; key++) {
                const formatName = windowSizes[key].name;
                const formatMax = windowSizes[key].max;
                if(key !== maxInterval) {
                    const formatNextMax = windowSizes[key + 1].max;
                    if(key === 0 
                        ? isBetween(width, formatMax)
                        : isBetween(width, formatMax, formatNextMax)
                    ) {
                        setFormat(formatName);
                        setWidth(width);
                    }
                } else {
                    if(width > windowSizes[key].max) {
                        setFormat(formatName);
                        setWidth(width);
                    }
                }
            }
        }
    },[width, lastWidth, setWidth, maxInterval, format]);

    return format;
};