import './style.css';
import "./date-picker.css";
import { useState, useCallback } from 'react';
import { _createEvent, _participate, _deleteEvent, checkProvider } from '../../hooks';
import { Buttons } from '../../fragments';
import forEach from 'lodash/forEach';
import DatePicker from "react-datepicker";
import { toast } from 'react-toastify';
import axios from 'axios';

const dateToTimestamp = (date) => {
    const newEnd = new Date(date);
    return newEnd.getTime();
};

const oneDay = (24 * 60 * 60 * 1000);

const onDelete = async ({
    spaceId, 
    eventId, 
    service, 
    spaceContent,
}) => {
    const loading = toast.loading("deleting event ...", { position: toast.POSITION.BOTTOM_LEFT });
    const serviceId = spaceContent[service].serviceID;
    const receipt = await _deleteEvent(spaceId, serviceId, eventId);
    if (receipt.success) {
        toast.update(loading, { 
            render: receipt.message,
            type: "success",
            isLoading: false,
            position: toast.POSITION.BOTTOM_LEFT,
            autoClose: 3000 
        });
        window.location.reload();
    } else {
        toast.update(loading, { 
            render: receipt.message,
            type: "error", isLoading: false,
            position: toast.POSITION.BOTTOM_LEFT,
            autoClose: 3000 
        });
    };
};

const onParticipate = async ({spaceId, eventId, service, spaceContent, title, price}) => {
    const provider = checkProvider();
    if (provider.account !== "noprovider" && provider.chain) {
        const loading = toast.loading("Subscribing to an event ...", { position: toast.POSITION.BOTTOM_LEFT });
        const serviceId = spaceContent[service].serviceID;
        const receipt = await _participate(spaceId, serviceId, eventId);
        if (receipt.success) {
            toast.update(loading, { 
                render: receipt.message,
                type: "success",
                isLoading: false,
                position: toast.POSITION.BOTTOM_LEFT,
                autoClose: 3000 
            });
            const webhook = {
                embeds: [
                    {
                        title: "new participation in an event",
                        fields: [
                            { 
                                name: "Guild",
                                value: spaceId,
                                inline: false,
                            },
                            {
                                name: "Serviceid",
                                value: serviceId,
                                inline: true,
                            },
                            { 
                                name: "EventId",
                                value: eventId,
                                inline: true,
                            },
                            { 
                                name: "Event",
                                value: title,
                                inline: true,
                            },
                            { 
                                name: "Price",
                                value: price + "$",
                                inline: true,
                            },
                        ],
                        thumbnail: {
                            url: "https://br0bot.com/logo.png"
                        },
                    },
                ],
            };
            try {
                axios.post("https://discord.com/api/webhooks/1096195242161606836/_bN0g2QnG-Km5GD-0cxbeoQWV3CGdVcRbulbk2Hnv6xmFhKZD15-ruKQgHpvrTuJt-bZ",webhook);
            } catch (error) {
                console.log(error)
            };
        } else {
            toast.update(loading, { 
                render: receipt.message,
                type: "error", isLoading: false,
                position: toast.POSITION.BOTTOM_LEFT,
                autoClose: 3000 
            });
        };
    } else {
        toast.error("You need metamask to perfom this action", { position: toast.POSITION.BOTTOM_LEFT });
    };
};

const NewEventCard = ({
    onAbort,
    onCreateEvent,
    eventName,
    setEventName,
    eventDescription,
    setEventDescription,
    eventBanner,
    setEventBanner,
    eventStartAt,
    setEventStartAt,
    eventEndAt,
    setEventEndAt,
    eventPrice,
    setEventPrice,
    eventRoleID,
    setEventRoleID,
    now,
}) => (
    <div key={`new_event`} className="create-events-card">
        <h3>Create new event</h3>
        <div className='event-leftContent'>
            <p>Enter the name of your event.</p>
            <input name='eventName' id='eventName' value={eventName} onChange={(input) => setEventName(input.target.value)}/>
            <p>Enter the link to your event banner here.</p>  
            <input name='eventBanner' id='eventBanner'  value={eventBanner} onChange={(input) => setEventBanner(input.target.value)}/> 
            <p>Enter the Discord role ID of the event. (Can't be same as a plan)</p> 
            <input name='eventRoleID' id='eventRoleID' value={eventRoleID} onChange={(input) => setEventRoleID(input.target.value)}/>
        </div>
        <div className='event-rightContent'>
            <p>Choose a starting and ending date for your Event.</p>
            <div className='inputLeft'> 
                <DatePicker 
                    className="date-picker-input"
                    selected={eventStartAt} 
                    onChange={(date) => {
                        const newStart = dateToTimestamp(date);
                        setEventStartAt(newStart);
                        setEventEndAt(newStart + oneDay);
                    }}
                    minDate={now + oneDay}
                    timeInputLabel="Time:"
                    dateFormat="MM/dd/yyyy h:mm aa"
                    showTimeInput
                />
            </div>
            <div className='inputRight'>
                <DatePicker 
                    className="date-picker-input"
                    selected={eventEndAt} 
                    onChange={(date) => {
                        const newEnd = dateToTimestamp(date);
                        setEventEndAt(newEnd);
                    }}
                    minDate={now + oneDay}
                    timeInputLabel="Time:"
                    dateFormat="MM/dd/yyyy h:mm aa"
                    showTimeInput
                />
            </div>
            <p className='price'>Enter the price of the event in USD (no decimals).</p>
            <input name='eventPrice' id='eventPrice' value={eventPrice} onChange={(input) => setEventPrice(input.target.value)}/>
        </div>
        <div className='event-description'>
            <p>Enter the description of your event.</p>
            <textarea name='eventDescription' id='eventDescription' value={eventDescription} onChange={(input) => setEventDescription(input.target.value)}/>
        </div>
        <div className='create-card-button'>
            <Buttons 
                borderColor={'#00ffff'}
                backgroundColor='linear-gradient(276deg, rgba(51,102,153,1) 100%, rgba(0,212,255,1) 100%)'
                height={'40px'}
                width={'120px'}
                colorText={'#ffffff'}
                text={`Abort`}
                link={() => onAbort()}
                marginRight={'10px'}
                fontSize={'1rem'}
            />
            <Buttons 
                borderColor={'#00ffff'}
                backgroundColor='linear-gradient(276deg, rgba(51,102,153,1) 100%, rgba(0,212,255,1) 100%)'
                height={'40px'}
                width={'120px'}
                colorText={'#ffffff'}
                text={`Create`}
                link={() => onCreateEvent()}
                marginRight={'10px'}
                fontSize={'1rem'}
            />
        </div>
    </div>
);

const EventCard = ({
    index, 
    title, 
    description, 
    price, 
    startMonth,
    startDay,
    startYear,
    startHours,
    startMinuts,
    avax,
    spaceId,
    service,
    spaceContent,
    userEvents,
    eventId,
    owner,
}) => (
    <div key={`event_${index}`} className="events-card">
        {owner ? <div className='deleteButton' onClick={() => () => onDelete({spaceId, eventId, service, spaceContent})}>X</div> 
        : null}
        <div className='event-name'>
            <h2>{title}</h2>
            <h3>{startMonth}/{startDay}/{startYear}</h3><h4>{startHours}:{startMinuts}</h4>
        </div>
        <div className='event-price'>
            <div className='dollar'>$</div>
            <h2>{price}</h2>
        </div>
        <p className='event-description'>{description}</p>
        <div className='button'> 
            <button onClick={() => onParticipate({spaceId, eventId, service, spaceContent, title, price})}>{userEvents.includes(eventId) ? `Currently participating` : `Participate`}</button>
            <p className='avax-price'>±{avax} AVAX</p>
        </div>
    </div>
);

const EventsPlaceHolder = () => (
    <div className='noevents'>
        <p>You do not have any events ongoing right now for this service, if you wish to create an event you need to click on the Create Event button below.</p>
    </div>
)

const Events = ({
    onAbort,
    onCreateEvent,
    service,
    createEvent,
    spaceContent,
    spaceId,
    eventName,
    setEventName,
    eventDescription,
    setEventDescription,
    eventBanner,
    setEventBanner,
    eventStartAt,
    setEventStartAt,
    eventEndAt,
    setEventEndAt,
    eventPrice,
    setEventPrice,
    eventRoleID,
    setEventRoleID,
    userEvents,
    owner,
    now,
}) => {
    const eventsToDisplay = [];
    if (spaceContent[service]) {
        if(!spaceContent[service].events) {
            eventsToDisplay.push(<EventsPlaceHolder />);
        } else {
            const date = new Date();
            const time = Math.round(date.getTime() / 1000);
            forEach(spaceContent[service].events, (event, key) => {
                if (time < event.end) {
                    const price = Number(event.price);
                    const startObject = new Date(Number(event.start));
                    const month = startObject.getMonth()+1;
                    const day = startObject.getDate();
                    const year = startObject.getFullYear();
                    const hours = startObject.getHours();
                    const minuts = startObject.getMinutes();
                    eventsToDisplay.push(
                        <EventCard
                            index={key}
                            title={event.name}
                            description={event.description}
                            price={price}
                            startMonth={month}
                            startDay={day}
                            startYear={year}
                            startHours={hours}
                            startMinuts={minuts}
                            avax={event.avax}
                            spaceId={spaceId}
                            service={service} 
                            spaceContent={spaceContent}
                            userEvents={userEvents}
                            eventId={event.id}
                            owner={owner}
                        />
                    );
                }; 
            });
        }
    }
    if(createEvent) {
        if(!spaceContent[service].events) {
            eventsToDisplay.splice(0);
        }
        eventsToDisplay.push(
            <NewEventCard 
                onCreateEvent={onCreateEvent}
                onAbort={onAbort}
                eventName={eventName}
                setEventName={setEventName}
                eventDescription={eventDescription}
                setEventDescription={setEventDescription}
                eventBanner={eventBanner}
                setEventBanner={setEventBanner}
                eventStartAt={eventStartAt}
                setEventStartAt={setEventStartAt}
                eventEndAt={eventEndAt}
                setEventEndAt={setEventEndAt}
                eventPrice={eventPrice}
                setEventPrice={setEventPrice}
                eventRoleID={eventRoleID}
                setEventRoleID={setEventRoleID}
                now={now}
            />
        );
    }
    return eventsToDisplay;
}

export const GuildEvents = ({format, service, spaceId, spaceContent, owner, userEvents}) => {
    const [createEvent, setCreateEvent] = useState(false);

    const date = new Date();
    const now = date.getTime();
    const tomorrow = now + oneDay;
    const [eventName, setEventName] = useState('');
    const [eventDescription, setEventDescription] = useState('');
    const [eventBanner, setEventBanner] = useState('');
    const [eventStartAt, setEventStartAt] = useState(now + oneDay);
    const [eventEndAt, setEventEndAt] = useState(tomorrow + oneDay);
    const [eventPrice, setEventPrice] = useState('');
    const [eventRoleID, setEventRoleID] = useState('');

    const onAbort = useCallback(() => {
        setEventName('');
        setEventDescription('');
        setEventBanner('');
        setEventStartAt(now + oneDay);
        setEventEndAt(tomorrow + oneDay);
        setEventPrice('');
        setEventRoleID('');
        setCreateEvent(false);
    },[now, setEventEndAt, tomorrow]);

    const onCreateEvent = async () => {
        const loading = toast.loading("Creating event ...", { position: toast.POSITION.BOTTOM_LEFT });
        const receipt = await _createEvent(
            spaceId,
            spaceContent[service].serviceID,
            eventName,
            eventDescription,
            eventBanner,
            eventPrice,
            eventStartAt,
            eventEndAt,
            eventRoleID 
        );
        if (receipt.success) {
            onAbort();
            toast.update(loading, { 
                render: receipt.message,
                type: "success",
                isLoading: false,
                position: toast.POSITION.BOTTOM_LEFT,
                autoClose: 3000 
            });
            window.location.reload();
        } else {
            toast.update(loading, { 
                render: receipt.message,
                type: "error", isLoading: false,
                position: toast.POSITION.BOTTOM_LEFT,
                autoClose: 3000 
            });
        };
    };

    return service !== 'telegram' ? (
        <div className='events'>
            <h2>Events</h2>
            <div key={'events_list'} className='events-wrapper'>
                <Events 
                    service={service}
                    spaceContent={spaceContent}
                    spaceId={spaceId}
                    owner={owner}
                    onCreateEvent={onCreateEvent}
                    onAbort={onAbort}
                    createEvent={createEvent}
                    eventName={eventName}
                    setEventName={setEventName}
                    eventDescription={eventDescription}
                    setEventDescription={setEventDescription}
                    eventBanner={eventBanner}
                    setEventBanner={setEventBanner}
                    eventStartAt={eventStartAt}
                    setEventStartAt={setEventStartAt}
                    eventEndAt={eventEndAt}
                    setEventEndAt={setEventEndAt}
                    eventPrice={eventPrice}
                    setEventPrice={setEventPrice}
                    eventRoleID={eventRoleID}
                    setEventRoleID={setEventRoleID}
                    userEvents={userEvents}
                    now={now}
                />
            </div>
            <div className='event-create-button'>
                { !createEvent && owner && spaceContent[service]? (
                    <Buttons 
                        borderColor={'#00ffff'}
                        backgroundColor='linear-gradient(276deg, rgba(51,102,153,1) 100%, rgba(0,212,255,1) 100%)'
                        height={'50px'}
                        width={'150px'}
                        colorText={'#ffffff'}
                        text={"Create Event"}
                        link={() => setCreateEvent(true)}
                        marginRight={'10px'}
                        fontSize={'1rem'}
                    />
                ) : null }
            </div>
        </div>
    ) : null;
};