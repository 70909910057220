import { RoutesController } from '../';
import { useWindowSize, useResponsiveFormat, useDefaultFormat } from '../../hooks';

export const WindowController = () => {
    const { width } = useWindowSize();
    
    const defaultFormat = useDefaultFormat(width) || 'medium';
    const windowFormat = useResponsiveFormat(defaultFormat, width);

    return <RoutesController format={windowFormat} />;
}
