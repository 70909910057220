import './style.css';
import { GuildDetails, GuildPlans, GuildEvents, CreateGuild, CreateService, GuildCustomerBar, Footer, Loading, GuildStats, HowToUse, Navbar } from '../../components';
import { _checkIfOwner, _getUserSubs, _getSpaceContent, _getUserEvents, checkChain} from '../../hooks';
import { isEmpty, map, forEach } from 'lodash';
import { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import {
    _getAccount,
} from '../../hooks';

const serviceAvailable = ["discord","telegram"];

const GuildManager = ({
    format,
    service, 
    setService, 
    spaceId, 
    spaceContent, 
    owner, 
    userSubs, 
    userEvents, 
    loading,
    setCreateService,
    connected}) => (
    <>  
        {
            loading ? (
                <Loading />
            ) : (
                <>
                {
                !connected ? 
                <Navbar
                    format={format}
                    link={"connect"}
                /> : null
                }
                <GuildDetails 
                    format={format}
                    spaceId={spaceId} 
                    spaceContent={spaceContent.guild} 
                    owner={owner} 
                />
                <GuildCustomerBar 
                    format={format}
                    service={service}
                    setService={setService}
                    spaceContent={spaceContent.service}
                    spaceId={spaceId}
                    owner={owner}
                    setCreateService={setCreateService}
                />
                { service === "telegram" && owner ? (
                            <HowToUse /> 
                        ) : null 
                }
                { owner ? (
                    <GuildStats 
                        format={format}
                        service={service} 
                        spaceContent={spaceContent.service}
                    />
                    ) : null
                }
                <GuildPlans 
                    format={format}
                    service={service} 
                    spaceId={spaceId} 
                    spaceContent={spaceContent.service} 
                    owner={owner} 
                    userSubs={userSubs}
                />
                { owner || spaceContent.service.telegram ? (
                    <GuildEvents 
                        format={format}
                        service={service} 
                        spaceId={spaceId} 
                        spaceContent={spaceContent.service} 
                        owner={owner} 
                        userEvents={userEvents}
                    />
                    ) : null 
                }
                <Footer format={format} />
                </>
            )
        }
    </>
);

export const Space = ({format}) => {
    const navigate = useNavigate();

    const { spaceId } = useParams();
    const [createGuild, setCreateGuild] = useState(spaceId === 'new');
    const [service, setService] = useState('discord');
    const [spaceContent, setSpaceContent] = useState({});
    const [serviceInUse, setserviceInUse] = useState(null);
    const [createService, setCreateService] = useState(false);
    const [owner, setOwner] = useState(null);
    const [userSubs, setUserSubs] = useState([]);
    const [userEvents, setUserEvents] = useState([]);
    const [connected, setConnected] = useState(false);
    const [loading, setLoading] = useState(true);

    const getSpaceContent = useCallback(async (guildId) => {
        const data = await _getSpaceContent(guildId);
        if (data.guild === false) {
            navigate(`/home/`, { replace: true })
        } else {
            setSpaceContent(data);
            document.title = `Br0bot | ${data.guild.name}`;
            if (isEmpty(spaceContent)) {
                const account = await _getAccount();
                if (account) {
                    setConnected(true);
                };
                setLoading(false);
            };
        };
    }, [navigate, spaceContent]);
    
    const checkGuildOwner = async (spaceId) => {
        const isOwner = await _checkIfOwner(spaceId);
        if (isOwner) {
            setOwner(true);
        } else setOwner(false);
    };

    const getUserSubs = async (spaceId) => {
        const subs = await _getUserSubs(spaceId);
        setUserSubs(subs);
    };

    const getUserEvents = async (spaceId) => {
        const eventSubs = await _getUserEvents(spaceId);
        if (eventSubs.length > 0) setUserEvents(eventSubs);
    };
        
    useEffect(() => {
        if (!createGuild) {
            checkChain().then((chain) => {
                if (chain) {
                    if(isEmpty(spaceContent)) {
                        getSpaceContent(spaceId);
                    } else {
                        if (!isEmpty(spaceContent) && serviceInUse === null) {
                            let serviceList = {};
                            forEach(serviceAvailable, (serviceName) => {
                                map(spaceContent.service,((_v, serviceKey) => {
                                    if (serviceName === serviceKey) {
                                        serviceList = {...serviceList, [serviceName]: true };
                                    }
                                }));
                            });
                            setserviceInUse(serviceList);
                        };
                        if (owner === null) {
                            checkGuildOwner(spaceId);
                            getUserSubs(spaceId);
                            getUserEvents(spaceId);
                        };
                    };
                } else window.location.href = "/";
            });
        };
    },[createGuild, getSpaceContent, owner, serviceInUse, spaceContent, spaceId]);
 
    return(
        <>
        {
             createGuild ? (
                <div className='background-create'>
                    <CreateGuild format={format} setCreateGuild={setCreateGuild} />
                </div>
            ) : createService ? (
                <div className='background-create'>
                    <CreateService format={format} service={service} spaceId={spaceId} setCreateService={setCreateService} />
                </div>
            ) : (
                <div className='background'>
                    <GuildManager format={format} service={service} setService={setService} spaceId={spaceId} setCreateService={setCreateService} spaceContent={spaceContent} owner={owner} userSubs={userSubs} userEvents={userEvents} loading={loading} connected={connected}/>
                </div>
            )
        }
        </>
    );
};