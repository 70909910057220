import './style.css';
import { Navbar } from '../../components/Navbar';
export const Tos = ({format}) => {
    return(
        <>
        <Navbar
            format={format}
            link={"home"}
        />
        <div className="tos">
            <div className="container">
                <h2 className="tos-title">General Terms and Conditions for the decentralized application Br0bot</h2>
                <p className='subtitle'>By using Br0bot, you agree to the following general terms and conditions:</p>
                <ul>
                    <li className="tos-block">
                        <h3 className="block-title">Definitions :</h3>
                        <p className="block-content">"Decentralized application" or "DApp" refers to Br0bot, which is a blockchain-based application that enables interaction between multiple users without requiring a trusted third party.<br/>"User" refers to any person who uses the decentralized application.</p>
                    </li>
                    <li className="tos-block">
                        <h3 className="block-title">Intellectual Property :</h3>
                        <p className="block-content">"All content, software, and technologies used to create this decentralized application are protected by intellectual property rights. The use of this application does not confer any ownership rights over these contents, software, or technologies. Including discord and telegram bot.</p>
                    </li>
                    <li className="tos-block">
                        <h3 className="block-title">Liability :</h3>
                        <p className="block-content">The use of this decentralized application is at your own risk. The creators of this application cannot be held responsible for any direct or indirect losses or damages caused by the use of this application.</p>
                    </li>
                    <li className="tos-block">
                        <h3 className="block-title">Use of the Application :</h3>
                        <p className="block-content">The use of this decentralized application is restricted to persons who have reached the age of majority in their country of residence. The use of this application is prohibited in countries where it is considered illegal.</p>
                    </li>
                    <li className="tos-block">
                        <h3 className="block-title">Modification of the General Terms and Conditions :</h3>
                        <p className="block-content">The creators of this decentralized application reserve the right to modify these general terms and conditions at any time and without notice. It is the user's responsibility to regularly consult the general terms and conditions to be aware of any changes.</p>
                    </li>
                    <li className="tos-block">
                        <h3 className="block-title">Applicable Law and Jurisdiction :</h3>
                        <p className="block-content">These general terms and conditions are governed by the law in force in the country of residence of the creators of this application. Any dispute related to the use of this application will be under the exclusive jurisdiction of the courts of the country of residence of the creators of this application.</p>
                    </li>
                    <li className="tos-block">
                        <h3 className="block-title">Content on the "space" page :</h3>
                        <p className="block-content">The content displayed on the "space" page is stored on the blockchain and is solely the responsibility of the user who created it. The creators of the Br0bot DApp are not responsible for any content displayed on the Space page, and do not endorse or support any opinions or views expressed by users through their content. Users of the Br0bot DApp acknowledge that any content displayed on the Space page is not created, endorsed, or verified by the creators of the DApp, and that they assume all risk and responsibility for their use of the content.</p>
                    </li>
                </ul>
                <p className='subtitle'>By using this decentralized application, you agree to these general terms and conditions in their entirety.</p>
            </div>
        </div>
        </>
    );
};