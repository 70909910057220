import React from 'react';
import './style.css';
import { checkProvider, _getOwnedGuild, _getGuild } from '../../hooks';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { motion } from 'framer-motion';

const ManageGuild = async (navigate, setGuildManager) => {
  const provider = await checkProvider();
  if (provider.account !== "noprovider" && provider.chain) {
      const ownedGuild = await _getOwnedGuild(provider.account);
      if (!ownedGuild) return toast.error(`Connect your wallet first`, { position: toast.POSITION.BOTTOM_LEFT });
      if (ownedGuild.length === 1) return navigate(`/space/${ownedGuild[0]}`);
      if (ownedGuild.length === 0) return toast.info(`You don't own  any guild`, { position: toast.POSITION.BOTTOM_LEFT });
      if (ownedGuild.length > 1) {
        toast.info(`Requesting the blockchain...`, { position: toast.POSITION.BOTTOM_LEFT });
        const guildList = [];
        for (let i = 0; i < ownedGuild.length; i++) {
          const guild = await _getGuild(ownedGuild[i]);
          const guildToPush = {
            name: guild.name,
            id: ownedGuild[i],
          };
          guildList.push(guildToPush);
        };
        setGuildManager(guildList);
      };
  } else {
    toast.error("Metamask isn't settled", { position: toast.POSITION.BOTTOM_LEFT });
  };
};
const RedirectToSpace = (navigate, setGuildManager, guildID) => {
  setGuildManager([]);
  navigate(`/space/${guildID}`);
}

export const Footer = ({format}) => {
  const navigate = useNavigate();
  const [guildManager, setGuildManager] = useState([]);
  return (
    <>
      { guildManager.length !== 0 ? (
        <div className='modal-guildManager-wrapper'>
          <motion.div initial={{scale:0}} animate={{scale: 1}}  className='modal-guildManager'>
            <div className='header-guildManager'>
              <p className='close-button' onClick={() => setGuildManager([])}>X</p>
              <h2>What guild do you want to manage?</h2> 
            </div>
            <div className='content-guildManager'>
              {
                <div className='guildManager-button-list'>
                  {guildManager.map((guild, key) => <button key={`button-${key}`} onClick={() => RedirectToSpace(navigate, setGuildManager, guild.id)}>{guild.name}</button>)}
                </div>
              }
            </div>
          </motion.div>
        </div>
      ) : null
      }
      <div className='footer-container'>
      <div className='footer'>
        <div className='footernav'>
          <a className='navlist-item' href='https://snowtrace.io/address/0x9ED916f7Dc549C821A26675c958BB85061be2ef6'>Smart contract</a>
          <a className='navlist-item' href="./space/new">Create guild</a>
          <p className='navlist-item' onClick={() => ManageGuild(navigate, setGuildManager, guildManager)}>Manage guild</p> 
          <a className='navlist-item' href='../tos'>Terms of use</a>
          <a className='navlist-item' href='https://discord.gg/nw6EwTUKA7'>Contact us</a>
        </div>
        <div className='copyright'>
          <p>&copy;2023 Br0bot. All Rights Reserved.</p>
        </div>
      </div>
      </div>
    </>
  );
};