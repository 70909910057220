export const responsive = {
    'small': {
        button: {
            height: '50px',
            width: '200px',
            marginRight: '0px',
            fontSize: '1.2rem',
        },
    },
    'medium': {
        button: {
            height: '50px',
            width: '200px',
            marginRight: '0px',
            fontSize: '1.2rem',
        },
    },
    'large': {
        button: {
            height: '50px',
            width: '200px',
            marginRight: '0px',
            fontSize: '1.2rem',
        },
    },
    'x-large': {
        button: {
            height: '50px',
            width: '200px',
            marginRight: '0px',
            fontSize: '1.2rem',
        },
    },
    'xx-large': {
        button: {
            height: '50px',
            width: '200px',
            marginRight: '0px',
            fontSize: '1.2rem',
        },
    },
    'xxx-large': {
        button: {
            height: '50px',
            width: '200px',
            marginRight: '0px',
            fontSize: '1.2rem',
        },
    }
};