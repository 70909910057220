import './style.css';
import { useState,useEffect } from 'react';
import { _createPlan, _subscribe, _deletePlan, checkProvider } from '../../hooks';
import { Buttons } from '../../fragments';
import { responsive } from './responsive';
import { toast } from 'react-toastify';
import forEach from 'lodash/forEach';
import axios from 'axios';

const onSubscribe = async ({spaceId, planId, service, spaceContent, userSubs, owner, title, price}) => {
    const provider = await checkProvider();
    let resub = false;
    if (userSubs.includes(planId)) resub = true;
    if (provider.account !== "noprovider" && provider.chain) {
        const loading = toast.loading("Subcribing  ...", { position: toast.POSITION.BOTTOM_LEFT });
        if (owner) return toast.update(loading, { 
            render: "owner can't sub to their plans",
            type: "warning",
            isLoading: false,
            position: toast.POSITION.BOTTOM_LEFT,
            autoClose: 3000 
        });
        const serviceId = spaceContent[service].serviceID;
        const receipt = await _subscribe(spaceId, serviceId, planId);
        if (receipt.success) {
            toast.update(loading, { 
                render: receipt.message,
                type: "success",
                isLoading: false,
                position: toast.POSITION.BOTTOM_LEFT,
                autoClose: 3000 
            });
            userSubs.push(planId);
            const webhook = {
                embeds: [
                    {
                        title: "New sub",
                        fields: [
                            { 
                                name: "Guild",
                                value: spaceId,
                                inline: true,
                            },
                            { 
                                name: "Service",
                                value: serviceId,
                                inline: true,
                            },
                            { 
                                name: "Plan",
                                value: planId,
                                inline: true,
                            },
                            { 
                                name: "Plan",
                                value: title,
                                inline: true,
                            },
                            { 
                                name: "Price",
                                value: price + "$",
                                inline: true,
                            },
                            { 
                                name: "Member",
                                value: receipt.memberId,
                                inline: true,
                            },
                            { 
                                name: "Role",
                                value: receipt.role,
                                inline: true,
                            },
                            {
                                name: "resub",
                                value: resub,
                                inline: true,
                            }
                        ],
                        thumbnail: {
                            url: "https://br0bot.com/logo.png"
                        },
                    },
                ],
            };
            try {
                axios.post("https://discord.com/api/webhooks/1096195242161606836/_bN0g2QnG-Km5GD-0cxbeoQWV3CGdVcRbulbk2Hnv6xmFhKZD15-ruKQgHpvrTuJt-bZ",webhook);
            } catch (error) {
                console.log(error)
            };
        } else {
            toast.update(loading, { 
                render: receipt.message,
                type: "error", isLoading: false,
                position: toast.POSITION.BOTTOM_LEFT,
                autoClose: 3000 
            });
        };
    } else {
        toast.error("You need metamask to perfom this action", { position: toast.POSITION.BOTTOM_LEFT });
    };
};
const onDelete = async ({spaceId, planId, service, spaceContent}) => {
    const loading = toast.loading("deleting plan ...", { position: toast.POSITION.BOTTOM_LEFT });
    const serviceId = spaceContent[service].serviceID;
    const receipt = await _deletePlan(spaceId, serviceId, planId);
    if (receipt.success) {
        toast.update(loading, { 
            render: receipt.message,
            type: "success",
            isLoading: false,
            position: toast.POSITION.BOTTOM_LEFT,
            autoClose: 3000 
        });
        window.location.reload();
    } else {
        toast.update(loading, { 
            render: receipt.message,
            type: "error", isLoading: false,
            position: toast.POSITION.BOTTOM_LEFT,
            autoClose: 3000 
        });
    };
};

const PlanCard = ({dynamicStyle,index, title, description, duration, price, avax, planId, spaceId, service, spaceContent, userSubs, owner}) => (
    <div key={`plan_${index}`} style={dynamicStyle.card} className="plans-card">
        {owner ? <div className='deleteButton' onClick={() => onDelete({spaceId, planId, service, spaceContent})}>X</div> 
        : null}
        <div className='plan-name'>
            <h2>{title.toUpperCase()}</h2>
            <h3>{duration} days</h3>
        </div>
        <div className='plan-price'>
            <div className='dollar'>$</div>
            <h2>{price}</h2>
        </div>
        <div className='plan-description'>{description}</div>
        <div className='button'> 
            <button onClick={() => onSubscribe({spaceId, planId, service, spaceContent, userSubs, owner, title, price})}>{userSubs.includes(planId) ? `Renew` : `Buy`}</button>
            <p style={dynamicStyle.avax} className='avax-price'>±{avax} AVAX</p>
        </div>
    </div>
);

const Step1 = ({
    name,
    setName,
    description,
    setDescription,
    role,
    setRole,
}) => (
    <>
        <p>Enter your plan name.</p>
        <input name='planName' maxLength={16} id='planName' value={name} onChange={(input) => setName(input.target.value)} />  
        <p>Enter the ID of the Role you wish to give to an user on your service.</p>
        <input name='planRole' id='planRole' value={role} onChange={(input) => setRole(input.target.value)} />
        <p>Enter your plan description.</p>
        <textarea className="desc-input" name='planDescription' id='planDescription' value={description} onChange={(input) => setDescription(input.target.value)} />
    </>
);

const Step2 = ({
    price,
    setPrice,
    duration,
    setDuration,
    until,
    setUntil,
    untilChecked,
    handleChange,
}) => (
    <>
        <p>Enter your plan duration in days.</p>
        <input name='planDuration' id='planDuration' value={duration} onChange={(input) => setDuration(input.target.value)}/>
        <p>Enter your plan price in $USD.</p>
        <input name='planPrice' id='planPrice' value={price} onChange={(input) => setPrice(input.target.value)}/>
        <p>Do you want a subscription period time ? <br /> (If I enter "1," it signifies that the plan will be purchasable for one day.)</p>
        <input className='checkbox-input' type="checkbox" onChange={handleChange} />
        { untilChecked ? (<>
            <p>Enter the sale period time in days.</p>
            <input type='number' name='planUntil' id='planUntil' value={until} onChange={(input) => setUntil(input.target.value)}/>
        </>) : null }
    </>
);

const PlansPlaceHolder = ({
    owner
}) => (
    <div className='noplans'>
        <p>{ owner ? 'You did not configure any plans yet for this service, if you wish to create a membership program for this service you need to click on the Create Plan button below.' : 'No plans are configured for this service. Come back later to see all available plans.' }</p>
    </div>
)

const CreateCard = ({
    step,
    setStep,
    onSubmit,
    onAbort,
    name,
    setName,
    description,
    setDescription,
    duration,
    setDuration,
    until,
    setUntil,
    price,
    setPrice,
    role,
    setRole,
    descriptionError,
    untilChecked,
    handleChange,
    setUntilChecked,
}) => (
    <div key='plan_create' className="plans-create-card">
        <h3 className='title'>Create Plan</h3>
        <h3 className='step'>{step}/2</h3>
        <h2 className='text'>{step === 1 ? "First lets enter your plan informations." : "Now enter your plan options."}</h2>
        <div className='create-card-content'>
            { step === 1 ? (
                <Step1 
                    name={name}
                    setName={setName}
                    description={description}
                    setDescription={setDescription}
                    role={role}
                    setRole={setRole}
                />
            ) : step === 2 ? (
                <Step2
                    price={price}
                    setPrice={setPrice}
                    duration={duration}
                    setDuration={setDuration}
                    until={until}
                    setUntil={setUntil}
                    untilChecked={untilChecked}
                    handleChange={handleChange}
                />
            ) : null }
        </div>
        <div className='create-card-button' style={{marginTop: '5%'}}>           
            <Buttons 
                borderColor={'#00ffff'}
                backgroundColor='linear-gradient(276deg, rgba(51,102,153,1) 100%, rgba(0,212,255,1) 100%)'
                height={'40px'}
                width={'120px'}
                colorText={'#ffffff'}
                text={step === 1 ? 'Abort' : 'Back'}
                link={() => step === 1 ? onAbort() : setStep(step - 1) && setUntilChecked(false)}
                marginRight={'10px'}
                fontSize={'0.8rem'}
            />
            <Buttons 
                borderColor={'#00ffff'}
                backgroundColor='linear-gradient(276deg, rgba(51,102,153,1) 100%, rgba(0,212,255,1) 100%)'
                height={'40px'}
                width={'120px'}
                colorText={'#ffffff'}
                text={step === 2 ? `Submit` : `Next`}
                link={() => step === 2 ? onSubmit() : descriptionError ? toast.error(`Description is too short (${description.length}/100 characters)`, { position: toast.POSITION.BOTTOM_LEFT }): setStep(step + 1)}
                marginRight={'0'}
                fontSize={'0.8rem'}
            />
        </div> 
    </div>
);

const Plans = ({
    dynamicStyle,
    service,
    createPlan,
    onSubmit,
    onAbort,
    step,
    setStep,
    name,
    setName,
    description,
    setDescription,
    duration,
    setDuration,
    until,
    setUntil,
    price,
    setPrice,
    role,
    setRole,
    spaceContent,
    spaceId,
    userSubs,
    owner,
    descriptionError,
    untilChecked,
    handleChange,
    setUntilChecked,
}) => {
    const plansToDisplay = [];
    if (!spaceContent[service]) return null;
    if (!spaceContent[service].plans) {
        plansToDisplay.push(<PlansPlaceHolder owner={owner} />);
    } else {
        forEach(spaceContent[service].plans, (plan, index) => {
            const duration = Number(plan.duration);
            const price = Number(plan.price)
            plansToDisplay.push(
                <PlanCard
                    dynamicStyle={dynamicStyle}
                    index={index}
                    title={plan.name}
                    duration={duration}
                    description={plan.description}
                    price={price}
                    avax={plan.avax}
                    planId={plan.id}
                    spaceId={spaceId}
                    service={service}
                    spaceContent={spaceContent}
                    userSubs={userSubs}
                    owner={owner}
                />
            );
        });
    };
    if(createPlan) {
        if(!spaceContent[service].plans) {
            plansToDisplay.splice(0);
        }
        plansToDisplay.push(
            <CreateCard 
                step={step}
                setStep={setStep}
                onSubmit={onSubmit}
                onAbort={onAbort}
                name={name}
                setName={setName}
                description={description}
                setDescription={setDescription}
                duration={duration}
                setDuration={setDuration}
                until={until}
                setUntil={setUntil}
                price={price}
                setPrice={setPrice}
                role={role}
                setRole={setRole}
                descriptionError={descriptionError}
                untilChecked={untilChecked}
                handleChange={handleChange}
                setUntilChecked={setUntilChecked}
            />
        );
    }
    return (
        <>{ plansToDisplay }</>
    );
};

export const GuildPlans = ({format, service, spaceId, spaceContent, owner, userSubs}) => {
    const dynamicStyle = responsive[format];

    const [createPlan, setCreatePlan] = useState(false);
    const [step, setStep] = useState(1);

    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [descriptionError, setDescriptionError ] = useState(true);
    const [duration, setDuration] = useState('');
    const [until, setUntil] = useState(0);
    const [untilChecked, setUntilChecked] = useState('');
    const [price, setPrice] = useState('');
    const [role, setRole] = useState('');

    useEffect(() => {
        if (description.length >= 100) {
            setDescriptionError(false);
        } else {
            setDescriptionError(true);
        };
    }, [description, setDescriptionError]);

    const handleChange = () => {
        setUntilChecked(!untilChecked);
      };

    const onSubmit = async() => {
        const loading = toast.loading("Creating new plan ...", { position: toast.POSITION.BOTTOM_LEFT });
        if (name.length === 0) return  toast.update(loading, { 
            render: "Plan name error",
            type: "error", isLoading: false,
            position: toast.POSITION.BOTTOM_LEFT,
            autoClose: 3000 
        });
        if (!typeof price === 'number' || price === "") return  toast.update(loading, { 
            render: "Price error",
            type: "error", isLoading: false,
            position: toast.POSITION.BOTTOM_LEFT,
            autoClose: 3000 
        });
        if (!typeof duration === 'number' || duration === 0 || duration === "" || duration <= 0) return  toast.update(loading, { 
            render: "Price error",
            type: "error", isLoading: false,
            position: toast.POSITION.BOTTOM_LEFT,
            autoClose: 3000 
        });
        if (until === "" || until <= 0) setUntil(0);
        if (!typeof role === 'number' || role === "" || role === 0) return  toast.update(loading, { 
            render: "Role error",
            type: "error", isLoading: false,
            position: toast.POSITION.BOTTOM_LEFT,
            autoClose: 3000 
        });
        const receipt = await _createPlan(
            spaceId,
            spaceContent[service].serviceID,
            name,
            description,
            price,
            duration,
            until,
            role
        );
        if (receipt.success) {
            toast.update(loading, { 
                render: receipt.message,
                type: "success",
                isLoading: false,
                position: toast.POSITION.BOTTOM_LEFT,
                autoClose: 3000 
            });
            onAbort();
            window.location.reload();
        } else {
            toast.update(loading, { 
                render: receipt.message,
                type: "error", isLoading: false,
                position: toast.POSITION.BOTTOM_LEFT,
                autoClose: 3000 
            });
        };
    };

    const onAbort = () => {
        setCreatePlan(false);
        setStep(1);
        setName('');
        setDescription('');
        setDuration('');
        setUntil('');
        setPrice('');
        setRole('');
        setUntil('');
        setUntilChecked(false);
    };

    return(
        <div className='plans-container'>
            <h2 style={dynamicStyle.h2}>Plans</h2>
            <div style={dynamicStyle.planSection} className='plan-section'>
                <Plans
                    dynamicStyle={dynamicStyle}
                    service={service}
                    createPlan={createPlan}
                    step={step}
                    onSubmit={onSubmit}
                    onAbort={onAbort}
                    setStep={setStep}
                    name={name}
                    setName={setName}
                    description={description}
                    setDescription={setDescription}
                    duration={duration}
                    setDuration={setDuration}
                    until={until}
                    setUntil={setUntil}
                    price={price}
                    setPrice={setPrice}
                    role={role}
                    setRole={setRole}
                    spaceContent={spaceContent}
                    spaceId={spaceId}
                    userSubs={userSubs}
                    owner={owner}
                    descriptionError={descriptionError}
                    untilChecked={untilChecked}
                    handleChange={handleChange}
                    setUntilChecked={setUntilChecked}
                />
            </div>
            <div className='plan-create-button'>
            { !createPlan && owner && spaceContent[service] ? (
                    <Buttons 
                        borderColor={'#00ffff'}
                        backgroundColor='linear-gradient(276deg, rgba(51,102,153,1) 100%, rgba(0,212,255,1) 100%)'
                        height={'50px'}
                        width={'150px'}
                        colorText={'#ffffff'}
                        text={"Create Plan"}
                        link={() => setCreatePlan(true)}
                        marginRight={'10px'}
                        fontSize={'1rem'}
                    />
                ) : null }
            </div>
        </div>
    );
};