import './style.css';
import { motion } from 'framer-motion';
const logofix = require('../../assets/images/loading_1.png');
const logoSpin = require('../../assets/images/loading_2.png');

export const Loading = () => {
    return (
        <div className='loading'>
            <motion.img className='loadingSpin' animate={{ rotate: [0, 360, 0]}} transition={{ repeat: Infinity,duration: 5}} src={logoSpin} alt='Loading Logo' />
            <img className="loadingFix" src={logofix} alt='Loading Logo' />
        </div>
    );
};

export default Loading;