export const responsive = {
    'small': { //375x667
        h2: {
            width: '90%',
            fontSize: '20px',
            marginTop: '5%',
        },
        planSection: {
            width: '90%',
            marginTop: '2%',
            paddingBottom: '5%',
        },
        card: {
            minWidth: '300px',
            height: '300px',
        },
        avax: {
            display: 'none',
        }
    },
    'medium': { //768
        h2: {
            width: '90%',
            fontSize: '20px',
            marginTop: '5%',
        },
        planSection: {
            width: '90%',
            marginTop: '2%',
        },
        card: {
            minWidth: '300px',
            height: '300px',
        },
        avax: {
            display: 'none',
        }
    },
    'large': {  //1224
    },
    'x-large': { //1920
    },
    'xx-large': { //2465
    },
    'xxx-large': {
    }
};