export const responsive = {
    'small': {
        storiesSelect: {
            marginTop: '6%',
        },
        storiesContainer: {
            marginTop: '10%',
        },
        storyCard: {
            display: 'block',
        },
        storyCardImage: {
            marginTop: '50px',
        },
        storyCardContentLeft: {
            textAlign: 'center',
            width: '100%',
        },
        storyCardContentRight: {
            textAlign: 'center',
            width: '100%',
        },
        title: {
            paddingTop: '20px',
        },
        textLeft: {
            paddingRight: '0',
        },
        textRight: {
            paddingLeft: '0',
        },
    },
    'medium': {
        storiesSelect: {
            marginTop: '3%',
        },
        storiesContainer: {
            marginTop: '3%',
        },
        storyCard: {
            display: 'inline-block',
        },
        storyCardContentLeft: {
            textAlign: 'right',
            width: '50%',
        },
        storyCardContentRight: {
            textAlign: 'left',
            width: '50%',
        },
        storyCardImage: {
            marginTop: '0',
        },
        title: {
            paddingTop: '0',
        },
        textLeft: {
            paddingRight: '50px',
        },
        textRight: {
            paddingLeft: '50px',
        },
    },
    'large': {
        storiesSelect: {
            marginTop: '3%',
        },
        storiesContainer: {
            marginTop: '3%',
        },
        storyCard: {
            display: 'inline-block',
        },
        storyCardContentLeft: {
            textAlign: 'right',
            width: '50%',
        },
        storyCardContentRight: {
            textAlign: 'left',
            width: '50%',
        },
        storyCardImage: {
            marginTop: '0',
        },
        title: {
            paddingTop: '0',
        },
        textLeft: {
            paddingRight: '50px',
        },
        textRight: {
            paddingLeft: '50px',
        },
    },
    'x-large': {
        storiesSelect: {
            marginTop: '3%',
        },
        storiesContainer: {
            marginTop: '3%',
        },
        storyCard: {
            display: 'inline-block',
        },
        storyCardContentLeft: {
            textAlign: 'right',
            width: '50%',
        },
        storyCardContentRight: {
            textAlign: 'left',
            width: '50%',
        },
        storyCardImage: {
            marginTop: '0',
        },
        title: {
            paddingTop: '0',
        },
        textLeft: {
            paddingRight: '50px',
        },
        textRight: {
            paddingLeft: '50px',
        },
    },
    'xx-large': {
        storiesSelect: {
            marginTop: '3%',
        },
        storiesContainer: {
            marginTop: '3%',
        },
        storyCard: {
            display: 'inline-block',
        },
        storyCardContentLeft: {
            textAlign: 'right',
            width: '50%',
        },
        storyCardContentRight: {
            textAlign: 'left',
            width: '50%',
        },
        storyCardImage: {
            marginTop: '0',
        },
        title: {
            paddingTop: '0',
        },
        textLeft: {
            paddingRight: '50px',
        },
        textRight: {
            paddingLeft: '50px',
        },
    },
    'xxx-large': {
        storiesSelect: {
            marginTop: '3%',
        },
        storiesContainer: {
            marginTop: '3%',
        },
        storyCard: {
            display: 'inline-block',
        },
        storyCardContentLeft: {
            textAlign: 'right',
            width: '50%',
        },
        storyCardContentRight: {
            textAlign: 'left',
            width: '50%',
        },
        storyCardImage: {
            marginTop: '0',
        },
        title: {
            paddingTop: '0',
        },
        textLeft: {
            paddingRight: '50px',
        },
        textRight: {
            paddingLeft: '50px',
        },
    }
};