export const responsive = {
    'small': {
        image: {
            width: '20px',
            height: '20px',
        },
        title: {
            fontSize: '1.2rem',
        },
        text: {
            fontSize: '0.9rem',
        },
        bulletPointsContainer: {
            marginTop: '6%',
            marginBottom: '6%',
        },
        bulletPointCards: {
            marginTop: '3%',
            display: 'block',
            width: '80%',
        },
    },
    'medium': {
        image: {
            width: '20px',
            height: '20px',
        },
        title: {
            fontSize: '1.2rem',
        },
        text: {
            fontSize: '0.9rem',
        },
        bulletPointsContainer: {
            marginTop: '3%',
            marginBottom: '0%',
        },
        bulletPointCards: {
            marginTop: '0%',
            display: 'inline-block',
            width: '20%',
        },
    },
    'large': {
        image: {
            width: '20px',
            height: '20px',
        },
        title: {
            fontSize: '1.2rem',
        },
        text: {
            fontSize: '0.9rem',
        },
        bulletPointsContainer: {
            marginTop: '3%',
            marginBottom: '0%',
        },
        bulletPointCards: {
            marginTop: '0%',
            display: 'inline-block',
            width: '20%',
        },
    },
    'x-large': {
        image: {
            width: '20px',
            height: '20px',
        },
        title: {
            fontSize: '1.2rem',
        },
        text: {
            fontSize: '0.9rem',
        },
        bulletPointsContainer: {
            marginTop: '3%',
            marginBottom: '0%',
        },
        bulletPointCards: {
            marginTop: '0%',
            display: 'inline-block',
            width: '20%',
        },
    },
    'xx-large': {
        image: {
            width: '20px',
            height: '20px',
        },
        title: {
            fontSize: '1.2rem',
        },
        text: {
            fontSize: '0.9rem',
        },
        bulletPointsContainer: {
            marginTop: '3%',
            marginBottom: '0%',
        },
        bulletPointCards: {
            marginTop: '0%',
            display: 'inline-block',
            width: '20%',
        },
    },
    'xxx-large': {
        image: {
            width: '20px',
            height: '20px',
        },
        title: {
            fontSize: '1.2rem',
        },
        text: {
            fontSize: '0.9rem',
        },
        bulletPointsContainer: {
            marginTop: '3%',
            marginBottom: '0%',
        },
        bulletPointCards: {
            marginTop: '0%',
            display: 'inline-block',
            width: '20%',
        },
    }
};