import './style.css';
import { responsive } from './responsive';
import {
    checkProvider,
} from '../../hooks';
import { toast } from 'react-toastify';

const click = async (link) => {
    if (link === "connect") {
        const connect = await checkProvider();
        if (connect.account === -32002)return toast.info("Please wait check your metamask extension.", { position: toast.POSITION.BOTTOM_LEFT });
        if (connect.account === "noprovider") return toast.error("Metamask is not installed", { position: toast.POSITION.BOTTOM_LEFT });
    } else window.location.href="./home"  
};

export const Navbar = ({format,link}) => {
    const dynamicStyle = responsive[format];
    return (
        <div style={dynamicStyle.navbar} className='navbar'>
            <div className='button'> 
                <button style={dynamicStyle.button} onClick={() => click(link)}>{link === "connect" ? "Connect" : "Home" }</button>
            </div>
        </div>
    );
};

export default Navbar;