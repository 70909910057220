import forEach from 'lodash/forEach';
import './style.css';
import { responsive } from './responsive';
import axios from 'axios';
import { useState, useEffect } from 'react';
import {
    _getAverageFee,
} from '../../hooks';

const avax = require('../../assets/images/avax.png');

const api = 'https://api.coingecko.com/api/v3/simple/price?ids=avalanche-2&vs_currencies=usd';

const orangeColor = {color:'#e84142'};

const bullets = [
    {
        img:'setup.png',
        title:'Easy to Setup',
        content:'Create your own decentralized membership program in only 3 easy steps.'
    },
    {
        img:'instant.png',
        title:'Instant payments',
        content:'Get paid instantly each time a customer purchase one of your plans.'
    },
    {
        img:'commission.png',
        title:'Low commissions',
        content:'No paid features, only 2.5% commission each time a customer subscribe to one of your plans.'
    },
    {
        img:'gas.png',
        title:'Low fees',
        content:'Avalanche Network offers one of the lowest transaction fees in the market.'
    },
];

const BulletList = ({bullets, dynamicStyle}) => {
    const bulletList = [];
    forEach(bullets, (bullet, key) => {
        const image = require(`../../assets/images/${bullet.img}`);
        bulletList.push(
            <div key={`bullet-point-${key}`} style={dynamicStyle.bulletPointCards} className='bulletpoint'>
                <img style={dynamicStyle.image} src={image} alt='' />
                <h3 style={dynamicStyle.title}>{bullet.title}</h3>
                <p style={dynamicStyle.text}>{bullet.content}</p>
            </div>
        );
    })

    return (
        <div style={dynamicStyle.bulletPointsContainer} className='bulletpoints'>
            {bulletList}
        </div>
    );
}

export const BulletPoints = ({format}) => {
    const dynamicStyle = responsive[format];

    const [avaxPrice, setAvaxPrice] = useState(0);
    const [gas, setGas] = useState(null);
    const [gasUSD, setGasUSD] = useState(null);

    const fetchPrice = () => {
    axios.get(api)
        .then((response) => {
            setAvaxPrice(response.data['avalanche-2'].usd);
        });
    };
    const getGas = async () => {
        const MaxGas = await _getAverageFee();
        setGas(MaxGas);
        if (MaxGas !== 0) {
            let usd = (MaxGas * avaxPrice) * 1000;
            usd = Math.trunc(usd) / 1000;
            setGasUSD(usd);
        };
    };
    useEffect(() => {
        if (avaxPrice === 0) fetchPrice();
        const interval = setInterval(fetchPrice, 60000);
        return () => {
            clearInterval(interval);
        };
    }, [avaxPrice]);
    useEffect(() => {
        if (gas === null) {
            getGas();
        }
    });
    return(
        <div className='bulletpoints-container'>
            <div className='bulletpoints-title'>
                <img src={avax} alt='Avalanche Network Logo' />
                <h2>Deployed on the Avalanche Network.</h2> 
                {  gas !== 0 && gasUSD ? (
                    <p>Contract Address: <span style={orangeColor}>0x9ED916f7Dc549C821A26675c958BB85061be2ef6</span><br />
                    AVAX: <span style={orangeColor}>{avaxPrice}</span> USD | Average gas fee: <span style={orangeColor}>{gas}</span> AVAX (<span style={orangeColor}>{gasUSD}</span>) USD</p>
                ) : (
                    <p>Contract Address: <span style={orangeColor}>0x9ED916f7Dc549C821A26675c958BB85061be2ef6</span><br />
                    AVAX: <span style={orangeColor}>{avaxPrice}</span> USD</p>
                )}
            </div>
            <BulletList bullets={bullets} dynamicStyle={dynamicStyle} />
        </div>
    );
};