export const responsive = {
    'small': { //375x667
        img: {
            display: 'inline-block',
            verticalAlign: 'top',
            height: '50px',
        },
        guild: {
            width: '90%',
            flexDirection: "column",
            minHeight: '180px',
        },
        left: {
            marginTop: '2%',
            border: "none", 
            height: "32%",
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        right: {
            height: "66%",
            width: '100%',
            alignItems: 'baseline',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'top',
            overflow: 'hidden',
        },
        h2: {
            marginRight: 'auto',
            marginLeft: 'auto',
        },
        desc: {
            width: '90%',
            height: '100%',
            fontSize: '10px',
            display: 'flex',
            alignItems: 'center',
            paddingLeft: '20px',
        }
    },
    'medium': { //1912x1368
        img: {
            display: 'inline-block',
            verticalAlign: 'top',
            height: '50px',
        },
        guild: {
            width: '90%',
            flexDirection: "column",
            minHeight: '180px',
        },
        left: {
            marginTop: '2%',
            border: "none", 
            height: "32%",
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        right: {
            height: "66%",
            width: '100%',
            alignItems: 'baseline',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'top',
            overflow: 'scroll',
        },
        h2: {
            marginRight: 'auto',
            marginLeft: 'auto',
        },
        desc: {
            width: '96%',
            height: '100%',
            fontSize: '0.8rem',
            display: 'flex',
            alignItems: 'center',
            paddingLeft: '20px',
        }
    },
    'large': {  //1280x800
        img: {
            display: 'inline-block',
            verticalAlign: 'top',
            height: '120px',
        },
        left: {
            height: "80%",
            width: '33%',
            borderRight:  '3px solid #00ffff',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        right: {
            height: "100%",
            width: '66%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            paddingLeft: '2%',
        }
    },
    'x-large': { //1920x1080
        img: {
            display: 'inline-block',
            verticalAlign: 'top',
            height: '130px',
        },
        left: {
            height: "80%",
            width: '33%',
            borderRight:  '3px solid #00ffff',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        right: {
            height: "100%",
            width: '66%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            paddingLeft: '2%',
        }
    },
    'xx-large': { //2465
        img: {
            display: 'inline-block',
            verticalAlign: 'top',
            height: '140px',
        },
        left: {
            height: "80%",
            width: '33%',
            borderRight:  '3px solid #00ffff',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        right: {
            height: "100%",
            width: '66%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            paddingLeft: '2%',
        }
    },
    'xxx-large': {
        img: {
            display: 'inline-block',
            verticalAlign: 'top',
            height: '150px',
        },
        left: {
            height: "80%",
            width: '33%',
            borderRight:  '3px solid #00ffff',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        right: {
            height: "100%",
            width: '66%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            paddingLeft: '2%',
        }
    }
};