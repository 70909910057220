import { useEffect } from 'react';
import {
  Routes,
  Route,
  Navigate,
  useLocation
} from "react-router-dom";
import { Home, Space, Tos } from '../../screens';

const pages = {
    home: {
        route: "home",
        title: 'Decentralized Membership Management Programs'
    },
    space: {
        route: "space",
        title: 'Create your Guild',
        arg: 'spaceId'
    },
    tos: {
        route: "tos",
        title: 'Terms of use'
    },
};

export const RoutesController = ({format}) => {
    const locations = useLocation();
    const locationsArray = locations.pathname.split("/")
    const currentLocation = locationsArray[1] || 'home';

    useEffect(() => {
        if (typeof pages[currentLocation] !== 'undefined') {
            document.title = `Br0bot - ${pages[currentLocation].title}`;
        } else  document.title = 'Br0bot - 404'
    }, [currentLocation])

    return(
       <Routes>
           <Route
               exact
               path="/" 
               element={
                   <Navigate to={`/${pages.home.route}`} />
               }
           />
           <Route 
               exact 
               path={`/${pages.home.route}`}
               element={
                   <Home format={format} />
               }
           />
           <Route
               exact
               path={`/${pages.space.route}`}
               element={
                   <Navigate to={`/${pages.home.route}`} />
               }
           />
           <Route 
               path={`/${pages.space.route}/:${pages.space.arg}`}
               element={
                   <Space format={format} />
               }
           />
           <Route 
               path={`/tos`}
               element={
                   <Tos format={format} />
               }
           />
           <Route 
               path="*" 
               element={
                   <Navigate to={`/${pages.home.route}`} />
               }
           />
       </Routes>
    );
};