import {
    _register,
    _checkRegister,
    _getAccount,
    _getMember, 
    _getMembersFromGuildService, 
    _join,
} from '../../hooks';
import { responsive } from './responsive';
import { Buttons } from '../../fragments';
import { useEffect, useState } from 'react';
import './style.css';
import { toast } from 'react-toastify';

const register = async ({ username, setRegistered}) => {
    const loading = toast.loading("Registering  ...", { position: toast.POSITION.BOTTOM_LEFT });
    const receipt = await _register(username);
    if (receipt.success) {
        toast.update(loading, { 
            render: receipt.message,
            type: "success",
            isLoading: false,
            position: toast.POSITION.BOTTOM_LEFT,
            autoClose: 3000 
        });
        setRegistered(true);
    } else {
        toast.update(loading, { 
            render: receipt.message,
            type: "error", isLoading: false,
            position: toast.POSITION.BOTTOM_LEFT,
            autoClose: 3000 
        });
    };
};
const checkRegister = async ({setRegistered, setUsername, setMemberId,setConnected, owner}) => {
    if (owner) {
        setRegistered(true);
        setUsername("it's good to see you here");
        setConnected(true);
    } else {
        const account = await _getAccount();
        if (account) {
            setConnected(true);
            const isRegister = await _checkRegister(account);
            if (isRegister.register !== false) {
                setRegistered(true);
                const member = await _getMember(isRegister.register);
                if (member !== false) {
                    setUsername(member);
                    setMemberId(isRegister.register);
                };
            };
        };
    };
};
const join = async ({ identifier, setJoined, spaceContent, service, spaceId}) => {
    const loading = toast.loading(`Joining ${service} ...`, { position: toast.POSITION.BOTTOM_LEFT });
    const guild = spaceId;
    if (!spaceContent) return toast.update(loading, { 
        render: "Error",
        type: "error", isLoading: false,
        position: toast.POSITION.BOTTOM_LEFT,
        autoClose: 3000 
    });
    const serviceId = spaceContent[service].serviceID;
    const receipt = await _join(guild, serviceId, identifier);
    if (receipt.success) {
        toast.update(loading, { 
            render: receipt.message,
            type: "success",
            isLoading: false,
            position: toast.POSITION.BOTTOM_LEFT,
            autoClose: 3000 
        });
        setJoined(true);
    } else {
        toast.update(loading, { 
            render: receipt.message,
            type: "error", isLoading: false,
            position: toast.POSITION.BOTTOM_LEFT,
            autoClose: 3000 
        });
    };
};
const checkJoin = async ({setJoined, memberId, service, spaceContent, owner}) => {
    if (owner) {
        setJoined(true);
    } else {
        if (spaceContent) {
            if (memberId !== 0) {
                const serviceMembers = await _getMembersFromGuildService(spaceContent[service].serviceID);
                if (serviceMembers.includes(memberId)) setJoined(true);
            };
        };
    };
};
const Register = ({ 
    registered, 
    setRegistered, 
    username, 
    usernameError,
    setUsername
}) => {
    return !registered ? (
        <>
            <input name='username' id='username' placeholder='Username' style={usernameError ? {color: '#F86F15'} : {color: '#336699'}} value={username} onChange={(input) => setUsername(input.target.value)}/>
            <Buttons 
                borderColor={'#ffffff'}
                backgroundColor='#336699'
                height={'35px'}
                width={'100px'}
                colorText={'#ffffff'}
                text={'Register'}
                link={() => usernameError ? null : register({username, setRegistered})}
                marginRight={'10px'}
                fontSize={'1rem'}
            />
        </>
    ) : (
        <p style={{marginTop:'3%'}}>Welcome back {username} !</p>
    );
};
const Join = ({ 
    joined, 
    setJoined, 
    identifier, 
    identifierError,
    setIdentifier, 
    spaceContent,
    service, 
    spaceId
}) => {
    return !joined ? (
        <>
            <input name='identifier' id='identifier' style={identifierError ?  {color: '#F86F15'} : {color: '#00FFFF'}} placeholder={`Account ID`} value={identifier} onChange={(input) => setIdentifier(input.target.value)}/>
            <Buttons 
               borderColor={'#00ffff'}
               backgroundColor='#336699'
               height={'35px'}
               width={'100px'}
               colorText={'#00ffff'}
                text={'Join'}
                link={() => identifierError ? null : join({identifier, setJoined, spaceContent, service, spaceId})}
                marginRight={'10px'}
                fontSize={'1rem'}
            />
        </>
    ) : (
        <p style={{marginTop:'3%'}}>Account ID: {identifier}</p>
    );
};
const selectService = (setService,setCreateService,owner,spaceContent,service) => {
    if (typeof spaceContent !== 'undefined') {
        setService(service);
    } else {
        if (owner) {
            setService(service);
            setCreateService(true);
        } else {
            toast.info("This service isn't available on this guild", { position: toast.POSITION.BOTTOM_LEFT });
        };
    };
}

export const GuildCustomerBar = ({format, service, setService, setCreateService, spaceContent, spaceId, owner}) => {
    const dynamicStyle = responsive[format];

    const [ username, setUsername ] = useState('');
    const [ usernameError, setUsernameError ] = useState(false);
    const [ identifier, setIdentifier ] = useState('');
    const [ identifierError, setIdentifierError ] = useState(false);
    const [ registered, setRegistered ] = useState(false);
    const [ connected, setConnected ] = useState(false);
    const [ memberId, setMemberId ] = useState(0);
    const [ joined, setJoined ] = useState(false);

    useEffect(() => {
        checkRegister({setRegistered, setUsername, setMemberId, setConnected, owner});
        if (!registered) {
            if (username.length > 0 && username.match(new RegExp(`^[A-Za-z0-9_.-]{3,24}$`)) !== null) {
                setUsernameError(false);
            } else {
                setUsernameError(true);
            }
        }
    }, [username, setUsernameError, registered, owner]);
    
    useEffect(() => {
        checkJoin({setJoined, memberId, service, spaceContent, owner});
        if (!joined) {
            if (identifier.length <= 20 && identifier.match(new RegExp(`^[0-9]*$`)) !== null) {
                setIdentifierError(false);
            } else {
                setIdentifierError(true);
            };
        };   
    }, [identifier, memberId, setIdentifierError, registered, service, spaceContent, joined, owner]);
    
    return(
        <div className='guildCustomerBar'>
            <div style={dynamicStyle.customerBar} className='customerBar'>
                <div style={dynamicStyle.buttons} className='buttons'>
                    <Buttons 
                        borderColor={service === 'discord' ? '#00ffff' : '#ffffff'}
                        backgroundColor='#336699'
                        height={'35px'}
                        width={'120px'}
                        colorText={service === 'discord' ? '#00ffff' : '#ffffff'}
                        text={'Discord'}
                        link={() => selectService(setService, setCreateService,owner,spaceContent['discord'],'discord')}
                        marginRight={'0px'}
                        fontSize={'1rem'}
                    />
                    <Buttons 
                        borderColor={service === 'telegram' ? '#00ffff' : '#ffffff'}
                        backgroundColor='#336699'
                        height={'35px'}
                        width={'120px'}
                        colorText={service === 'telegram' ? '#00ffff' : '#ffffff'}
                        text={'Telegram'}
                        link={() => selectService(setService, setCreateService,owner,spaceContent['telegram'],'telegram')}
                        marginRight={'0px'}
                        fontSize={'1rem'}
                    />
                </div>
                <div style={dynamicStyle.text} className='text'>
                    { !connected ? (
                        <p style={dynamicStyle.p}>You need to connect if you want interact with this guild</p>
                    ) : owner && spaceContent[service] && service === "discord" && spaceContent[service].plans && spaceContent[service].plans.length > 0  ? (
                        <p style={dynamicStyle.p}>Invite the bot on your discord server.</p>
                    ) : !owner && !joined && !registered ? (
                        <p style={dynamicStyle.p}>If you wish to join one this guild services, you need to register first, enter a username and join us!</p>
                    ) : !owner && !joined && registered ? (
                        <p style={dynamicStyle.p}>To join our {service} enter your account ID ! (Click here to know more)</p>
                    ) : (
                        <p style={{fontSize:'1rem'}}>Welcome back, {username} !</p>
                    )}
                </div>
                <div style={dynamicStyle.inputs} className='inputs'> 
                    { owner && spaceContent[service] && service === "discord" && spaceContent[service].plans && spaceContent[service].plans.length > 0 && connected  ? (
                        <Buttons 
                                borderColor={'#ffffff'}
                                backgroundColor='#336699'
                                height={'35px'}
                                width={'150px'}
                                colorText={'#ffffff'}
                                text={'Invit Br0bot'}
                                link={() => window.location.href = "https://discord.com/api/oauth2/authorize?client_id=1004802110212284486&permissions=17601047784528&scope=bot"}
                                marginRight={'10px'}
                                fontSize={'1rem'}
                            />
                        ) : !owner && !joined && !registered && connected ? (
                            <Register
                                service={service}
                                username={username}
                                usernameError={usernameError}
                                setUsername={setUsername} 
                                registered={registered}
                                setRegistered={setRegistered}
                            /> 
                        ) : !owner && !joined && registered && connected ? (
                            <Join
                                service={service}
                                identifier={identifier}
                                identifierError={identifierError}
                                setIdentifier={setIdentifier}
                                joined={joined}
                                setJoined={setJoined}
                                spaceContent={spaceContent}
                                spaceId={spaceId}
                            />
                        ) : null}
                </div>
            </div>
        </div>
    );
};