export const responsive = {
    'small': {
        navbar: {
            position: 'relative',
            zIndex: '100',
            width: '100%',
            height: '50px',
            marginBottom: '16px'
        },
        button : {
            marginTop: '10px',
            marginRight: '10px',
            width: '100px',
            height: '40px',
            fontSize: '16px',
        }
    },
    'medium': {
        navbar: {
            position: 'relative',
            zIndex: '100',
            width: '100%',
            height: '50px',
            marginBottom: '16px'
        },
        button : {
            marginTop: '10px',
            marginRight: '10px',
            width: '150px',
            height: '40px',
            fontSize: '16px',
        }
    },
    'large': {  
        navbar: {
            position: 'fixed',
            height: '50px',
            marginBottom: '40px'
        },
        button : {
            marginTop: '30px',
            marginRight: '30px',
            width: '150px',
            height: '40px',
            fontSize: '16px',
        }
    },
    'x-large': {
        navbar: {
            position: 'fixed',
            height: '50px',
            marginBottom: '40px'
        },
        button : {
            marginTop: '30px',
            marginRight: '30px',
            width: '180px',
            height: '50px',
            fontSize: '16px',
        }
    },
    'xx-large': {
        navbar: {
            position: 'fixed',
            height: '50px',
            marginBottom: '40px'
        },
        button : {
            marginTop: '30px',
            marginRight: '30px',
            width: '200px',
            height: '50px',
            fontSize: '16px',
        }
    },
    'xxx-large': {
        navbar: {
            position: 'fixed',
            height: '50px',
            marginBottom: '40px'
        },
        button : {
            marginTop: '30px',
            marginRight: '30px',
            width: '200px',
            height: '60px',
            fontSize: '16px',
        }
    }
};