import './style.css';

export const Buttons = ({ borderColor, colorText, backgroundColor, height, width, text, link, marginRight, fontSize }) => {
    return(
        <div onClick={() => link()} className='button-container' style={{marginRight}}>
            <div className='button' style={{
                    border:`${borderColor} solid 2px`,
                    background: backgroundColor,
                    height,
                    width
                }}>
                    <div className='button-text' style={{color: colorText, fontSize}}>{text}</div>
            </div>
        </div>
    );
}