import { _createGuild, _createService, _createPlan } from '../../hooks';
import { responsive } from './responsive';
import './style.css';
import { Buttons } from '../../fragments';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { toast } from 'react-toastify';
import axios from 'axios';

const logo = require('../../assets/images/logo.png');

const helper = {
    'discord': {
        'guildID': {
            title: 'How to retreive your discord Guild ID:',
            description: 'On discord go to User Settings, advanced and turn on "Developer Mode"',
            img: require('../../assets/images/DiscordGuildId.jpg'),
            description2:'Then right click on your server and copy ID',
        },
        'roleID': {
            title: 'How to retreive you RoleID',
            description: 'On discord go to your Server Settings, Roles.',
            img: require('../../assets/images/roleId.jpg'),
            description2: 'Then right click on the role and copy ID.',
        }
    },
    'telegram': {
        'guildID': {
            title: 'How to retreive your telegram group ID:',
            description: `Once the bot in your group simply send /groupe in the group chat.`,
            img: require('../../assets/images/telegramGuildId.jpg'),
            description2: 'Then copy the id (remove substraction sign).',
        },
        'roleID': {
            title: 'How to retreive you RoleID',
            description: 'Simply send /id in the br0bot chat.',
        }
    }
};
const Form = ({
    step,
    stepName,
    help,
    setHelp,
    newGuildName,
    setNewGuildName,
    newGuildDescription,
    setNewGuildDescription,
    newGuildLogo,
    setNewGuildLogo,
    newGuildContinent,
    setNewGuildContinent,
    newServiceName,
    setNewServiceName,
    newServiceId,
    setNewServiceId,
    newPlanName,
    setNewPlanName,
    newPlanDescription,
    setNewPlanDescription,
    newPlanRole,
    setNewPlanRole,
    newPlanDuration,
    setNewPlanDuration,
    newPlanPrice,
    setNewPlanPrice,
    dynamicStyle,
    onSubmitGuild,
    onSubmitService,
    onSubmitPlan,
    onAbort,
}) => (
    <div className='content-area'>
        <h1>[{step}/3] { step ===  1 ? 'Create your ' : step === 2 ? 'Configure a ' :  'Create your first '}<span style={{color:'#00ffff'}}>{stepName}</span> !</h1>
        { step === 1 ? (
            <>
                <input name='guildName' id='guildName' type='text' placeholder='Name' value={newGuildName} onChange={(input) => setNewGuildName(input.target.value)}/>
                <p>This is the name your guildspace, bot and smart contract will use.</p>
                <textarea name='guildDescription' id='guildDescription' type='text' placeholder='Description' value={newGuildDescription} onChange={(input) => setNewGuildDescription(input.target.value)}>
                </textarea>
                <p>This is the description your guildspace and smart contract will use.</p>
                <input name='guildLogo' id='guildLogo' type='text' placeholder='Brand Logo' value={newGuildLogo} onChange={(input) => setNewGuildLogo(input.target.value)}/>
                <p style={{marginBottom: '5%'}}>This is an image link your guildspace will use to display your brands logo.</p>
                <div className='subLeftRow'>
                    <select style={{marginTop: '5%'}} name="guildContinent" id="guildContinent" value={newGuildContinent} onChange={(input) => setNewGuildContinent(input.target.value)}>
                        <option value="EU" default>Europe</option>
                        <option value="AF"> Africa</option>
                        <option value="AS">Asia</option>
                        <option value="US">North America</option>
                        <option value="OC">Oceania</option>
                        <option value="SA">South America</option>
                    </select>
                </div>
                <div className='subRightRow'>
                    <p className='continentText'>This is the continent the bot will interact from, to optimize time responses.</p>
                </div>
                <p style={{marginTop: '5%'}}>Please do not use special characters (only utf-8) in the form fields. Also make sure you have enough founds to run the transaction.<br /><br/>Do not send funds to the smart contract affiliated wallets, all funds will be lost.</p>
            </>
        ) : step === 2 ? (
            <>
                <p  style={{marginBottom: '15%'}}>Now that your guild is added to our contract, you need to attach a service our bot will operate on. Choose a service in the following menu and add the requested informations.</p>
                <div className='subLeftRow'>
                    <select name="services" id="services" value={newServiceName} onChange={(input) => setNewServiceName(input.target.value)}>
                        <option value="discord" default>Discord</option>
                        <option value="telegram"> Telegram</option>
                    </select>
                </div>
                <div className='subRightRow'>
                    <p className='continentText'>This is the list of services our bot is supported on.</p>
                </div>
                <input name='serviceId' id='serviceId' type='text' placeholder='Guild ID' value={newServiceId} onChange={(input) => setNewServiceId(input.target.value)}/>
                <p>This is the ID of your {newServiceName} guild. (<span className='helper' onClick={() => help === false ? setHelp('guildID') : setHelp(false)}>Learn More</span>)</p>

                <p style={{marginTop: '5%'}}>Please do not use special characters (only utf-8) in the form fields. Also make sure you have enough founds to run the transaction.<br /><br/>Do not send funds to the smart contract affiliated wallets, all funds will be lost.</p>
            </>
        ) : (
            <>
                <input name='planName' maxLength={16} id='planName' placeholder='Your Plan Name' value={newPlanName} onChange={(input) => setNewPlanName(input.target.value)} />
                <p>Enter your plan name.</p>
                <textarea name='planDescription' id='planDescription' placeholder='Your Plan Description' value={newPlanDescription} onChange={(input) => setNewPlanDescription(input.target.value)} >
                </textarea>
                <p>Enter your plan description.</p>
                {newServiceName === "discord" ? (
                    <>
                    <input name='planRole' id='planRole' placeholder={`Role ID`} value={newPlanRole} onChange={(input) => setNewPlanRole(input.target.value)} />
                    <p>Enter the ID of the Role you wish to give to an user on your service. 
                    (<span className='helper' onClick={() => help === false ? setHelp('roleID') : setHelp(false)}>Learn more</span>)
                    </p>
                    </>
                ) : null
                }
                <input name='planDuration' id='planDuration' placeholder='Plan Duration (ex: 7)' value={newPlanDuration} onChange={(input) => setNewPlanDuration(input.target.value)}/>
                <p>Enter your plan duration in days.</p>
                <input name='planPrice' id='planPrice' placeholder='Price in USD' value={newPlanPrice} onChange={(input) => setNewPlanPrice(input.target.value)}/>
                <p>Enter your plan price in USD, no decimals.</p>
                <p style={{marginTop: '5%'}}> Please do not use special characters (only utf-8) in the form fields. Also make sure you have enough founds to run the transaction.<br /><br/>Do not send funds to the smart contract affiliated wallets, all funds will be lost.</p>
            </>
        )}
        <div className='buttons-area'>
            {
                step === 1 ? 
                <Buttons 
                    borderColor='#00ffff'
                    backgroundColor='#07073d'
                    height={dynamicStyle.button.height}
                    width={dynamicStyle.button.width}
                    colorText='#00ffff'
                    text={step === 1 ? 'Abort' : 'Back'}
                    link={() => onAbort()}
                    marginRight={dynamicStyle.button.marginRight}
                    fontSize={dynamicStyle.button.fontSize}
                /> : null
            }
            <Buttons 
                borderColor='#00ffff'
                backgroundColor='#07073d'
                height={dynamicStyle.button.height}
                width={dynamicStyle.button.width}
                colorText='#00ffff'
                text={step === 1 ? 'Create Guild' : step === 2 ? 'Create Service' : 'Create Plan' }
                link={() => step === 1 ? onSubmitGuild() : step === 2 ? onSubmitService() : onSubmitPlan()}
                marginRight={dynamicStyle.button.marginRight}
                fontSize={dynamicStyle.button.fontSize}
            />
        </div>
    </div>
);

const Success = ({step, newServiceName, newGuild, newService}) => (
    <>
        { step >= 2 ? (
            <>
                <h2>You successfully created your first Guild !</h2>
                <p>Tx: <a href={`https://snowtrace.io/tx/` + newGuild.hash} target='_blank' rel="noreferrer">{newGuild.hash}</a></p>
                <p>GuildSpace: <a href={`https://br0bot.com/space/${newGuild.guildId}`} target='_blank' rel="noreferrer">{`https://br0bot.com/space/${newGuild.guildId}`}</a></p>
            </>
        ) : null }
        { step >= 3 ?  (
            <>  
                <div style={{marginTop: '5%'}} />
                <h2>Your {newServiceName} as been configurated !</h2>
                <p>Tx: <a href={`https://snowtrace.io/tx/` + newService.hash} target='_blank' rel="noreferrer">{newService.hash}</a></p>
            </>
        ) : null }
        { step === 1 ? (
            <img className='logo-img' src={logo} alt='Logo Br0bot' />
        ) : null }
    </>
);

const Helper = ({help, newServiceName, setImageViewer}) => (
    <>
        <h2>{ helper[newServiceName][help].title }</h2>
        <p>{ helper[newServiceName][help].description }</p>
        <img onClick={() => setImageViewer(helper[newServiceName][help].img)} className='logo-discordguildId' src={helper[newServiceName][help].img} alt='discord developer helper' />
        {helper[newServiceName][help].description2 ? <p>{ helper[newServiceName][help].description2 }</p> : null}
        {newServiceName === "discord" ? (
            <a href={`https://support.discord.com/hc/en-us/articles/206346498-Where-can-I-find-my-User-Server-Message-ID-#:~:text=Obtaining%20Server%20IDs%20%2D%20Mobile%20App,name%20and%20select%20Copy%20ID.`} target='_blank' rel="noreferrer">More help here</a>
        ) : null}
    </>
);

const Side = ({step, help, newServiceName, newGuild, newService, setImageViewer}) => {
    const marginContent = {
        marginTop: step >= 2 ? '25%' : '20%',
        marginRight: step >= 2 ? '5%' : '20%',
        marginLeft: step >= 2 ? '5%' : '20%', 
    };
    return (
        <div className='logo-area' style={marginContent}>
            { help === false ? (
                <Success 
                    step={step}
                    newServiceName={newServiceName}
                    newGuild={newGuild}
                    newService={newService}
                />
            ) : (
                <Helper 
                    help={help}
                    newServiceName={newServiceName}
                    setImageViewer={setImageViewer}
                />
            ) }
        </div>
    );
};

export const CreateGuild = ({format, setCreateGuild}) => {
    const dynamicStyle = responsive[format];

    const navigate = useNavigate();

    const [step, setStep] = useState(1);
    const [stepName, setStepName] = useState('Guild');

    const [help, setHelp] = useState(false);

    const [newGuildName, setNewGuildName] = useState('');
    const [newGuildDescription, setNewGuildDescription] = useState('');
    const [newGuildLogo, setNewGuildLogo] = useState('');
    const [newGuildContinent, setNewGuildContinent] = useState('EU');

    const [newGuild, setnewGuild] = useState({});
    
    const [newServiceName, setNewServiceName] = useState('discord');
    const [newServiceId, setNewServiceId] = useState('');

    const [newService, setnewService] = useState({});

    const [newPlanName, setNewPlanName] = useState('');
    const [newPlanDescription, setNewPlanDescription] = useState('');
    const [newPlanRole, setNewPlanRole] = useState('');
    const [newPlanDuration, setNewPlanDuration] = useState('');
    const [newPlanPrice, setNewPlanPrice] = useState('');

    const [imageViewer, setImageViewer] = useState('');

    const onSubmitGuild = async () => {
        const loading = toast.loading("Creating new guild ...", { position: toast.POSITION.TOP_LEFT });
        if (newGuildName.length === 0) return  toast.update(loading, { 
            render: "Guild name can't be empty",
            type: "error", isLoading: false,
            position: toast.POSITION.BOTTOM_LEFT,
            autoClose: 3000 
        });
        if (newGuildDescription.length < 50) return  toast.update(loading, { 
            render: "Guild description is too short",
            type: "error", isLoading: false,
            position: toast.POSITION.BOTTOM_LEFT,
            autoClose: 3000 
        });
        if (newGuildLogo.length === 0) return  toast.update(loading, { 
            render: "Please link us a logo for your guild",
            type: "error", isLoading: false,
            position: toast.POSITION.BOTTOM_LEFT,
            autoClose: 3000 
        });
        const receipt = await _createGuild(newGuildName,newGuildDescription,newGuildContinent,newGuildLogo,setStep,setStepName);
        if (receipt.success) {
            toast.update(loading, { 
                render: receipt.message,
                type: "success",
                isLoading: false,
                position: toast.POSITION.TOP_LEFT,
                autoClose: 3000 
            });
            setnewGuild(receipt);
            setStep(2);
            setStepName('Service');
            setHelp(false);
            const webhook = {
                content: "`@everyone",
                embeds: [
                    {
                        title: "new Guild (contract)",
                        fields: [
                            { 
                                name: "Guild",
                                value: newGuildName,
                                inline: false,
                            },
                            { 
                                name: "Location",
                                value: newGuildContinent,
                                inline: true,
                            },
                            { 
                                name: "Hash",
                                value: receipt.hash,
                                inline: false,
                            },
                        ],
                        thumbnail: {
                            url: "https://br0bot.com/logo.png"
                        },
                    },
                ],
            };
            try {
                axios.post("https://discord.com/api/webhooks/1096195242161606836/_bN0g2QnG-Km5GD-0cxbeoQWV3CGdVcRbulbk2Hnv6xmFhKZD15-ruKQgHpvrTuJt-bZ",webhook);
            } catch (error) {
                console.log(error)
            };
        } else {
            toast.update(loading, { 
                render: receipt.message,
                type: "error", isLoading: false,
                position: toast.POSITION.TOP_LEFT,
                autoClose: 3000 
            });
        };
    };

    const onSubmitService = async () => {
        let serviceID = 0;
        const loading = toast.loading("Creating new service ...", { position: toast.POSITION.TOP_LEFT });
        if (newServiceId.startsWith('-')) {
            serviceID = newServiceId.substring(1)
        } else serviceID = newServiceId

        if (isNaN(serviceID)) return toast.update(loading, { 
            render: "Wrong ID",
            type: "error", isLoading: false,
            position: toast.POSITION.BOTTOM_LEFT,
            autoClose: 3000 
        });
        const receipt = await _createService(newGuild.guildId,newServiceName,serviceID,setStep,setStepName);
        if (receipt.success) {
            toast.update(loading, { 
                render: receipt.message,
                type: "success",
                isLoading: false,
                position: toast.POSITION.TOP_LEFT,
                autoClose: 3000, 
            });
            setnewService(receipt);
            setStep(3);
            setStepName('Plan');
            setHelp(false);
        } else {
            toast.update(loading, { 
                render: receipt.message,
                type: "error", isLoading: false,
                position: toast.POSITION.TOP_LEFT,
                autoClose: 3000,
            });
        };
    };

    const onSubmitPlan = async () => {
        let role = 0;
        if (newServiceName !== "telegram") role = newPlanRole;
        const spaceId = newService.guildId;
        const until = 0;
        const loading = toast.loading("Creating new plan ...", { position: toast.POSITION.BOTTOM_LEFT });

        if (newPlanName.length === 0) return  toast.update(loading, { 
            render: "Plan name error",
            type: "error", isLoading: false,
            position: toast.POSITION.BOTTOM_LEFT,
            autoClose: 3000 
        });
        if (newPlanDescription.length <= 100) return  toast.update(loading, { 
            render: "description error",
            type: "error", isLoading: false,
            position: toast.POSITION.BOTTOM_LEFT,
            autoClose: 3000 
        });
        if (!typeof newPlanPrice === 'number' || newPlanPrice === "") return  toast.update(loading, { 
            render: "Price error",
            type: "error", isLoading: false,
            position: toast.POSITION.BOTTOM_LEFT,
            autoClose: 3000 
        });
        if (!typeof newPlanDuration === 'number' || newPlanDuration === 0 || newPlanDuration === "" || newPlanDuration <= 0) return  toast.update(loading, { 
            render: "Price error",
            type: "error", isLoading: false,
            position: toast.POSITION.BOTTOM_LEFT,
            autoClose: 3000 
        });
      
        const receipt = await _createPlan(
            newService.guildId,
            newService.serviceId,
            newPlanName,
            newPlanDescription,
            newPlanPrice,
            newPlanDuration,
            until,
            role
        );
        if (receipt.success) {
            toast.update(loading, { 
                render: receipt.message,
                type: "success",
                isLoading: false,
                position: toast.POSITION.TOP_LEFT,
                autoClose: 3000 
            });
            setCreateGuild(false);
            return navigate(`/space/${spaceId}`, { replace: true });
        } else {
            toast.update(loading, { 
                render: receipt.message,
                type: "error", isLoading: false,
                position: toast.POSITION.TOP_LEFT,
                autoClose: 3000 
            });
        };
    };

    const onAbort = () => {
        if(step === 1) {
            setNewGuildName('');
            setNewGuildDescription('');
            setNewGuildLogo('');
            setNewGuildContinent('');
            navigate(`/home`, { replace: true });
        } else if(step === 2) {
            setStepName('Guild');
        } else if(step === 3) {
            setStepName('Service');
        }

        if(step > 1) {
            setStep(step - 1);
        } 
        setHelp(false);
    };

    return(
        <div className='createGuild'>
            { imageViewer !== "" ? (
                <div className='image-viewer'>
                    <img className='image-viewer' src={imageViewer} alt='imageViewer' />
                    <button className='close-imageViewer' onClick={() => setImageViewer('')}>Close</button>
                </div>
            ) : null

            }
            <div className='leftRow'>
                <Form 
                    step={step}
                    stepName={stepName}
                    help={help}
                    setHelp={setHelp}
                    newGuildName={newGuildName}
                    setNewGuildName={ setNewGuildName}
                    newGuildDescription={newGuildDescription}
                    setNewGuildDescription={setNewGuildDescription}
                    newGuildLogo={newGuildLogo}
                    setNewGuildLogo={setNewGuildLogo}
                    newGuildContinent={newGuildContinent}
                    setNewGuildContinent={setNewGuildContinent}
                    newServiceName={newServiceName}
                    setNewServiceName={setNewServiceName}
                    newServiceId={newServiceId}
                    setNewServiceId={setNewServiceId}
                    newPlanName={newPlanName}
                    setNewPlanName={setNewPlanName}
                    newPlanDescription={newPlanDescription}
                    setNewPlanDescription={setNewPlanDescription}
                    newPlanRole={newPlanRole}
                    setNewPlanRole={setNewPlanRole}
                    newPlanDuration={newPlanDuration}
                    setNewPlanDuration={setNewPlanDuration}
                    newPlanPrice={newPlanPrice}
                    setNewPlanPrice={setNewPlanPrice}
                    dynamicStyle={dynamicStyle}
                    onSubmitGuild={onSubmitGuild}
                    onSubmitService={onSubmitService}
                    onSubmitPlan={onSubmitPlan}
                    onAbort={onAbort}
                />
            </div>
            <div className='rightRow'>
                <Side 
                    step={step}
                    help={help}
                    newServiceName={newServiceName}
                    newGuild={newGuild}
                    newService={newService}
                    setImageViewer={setImageViewer}
                />
            </div>
        </div>
    );
};