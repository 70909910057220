import './style.css';
import { useState, useEffect } from 'react';
import { isEmpty } from 'lodash';
import { _getMembersFromGuildService, _getSubsStats } from '../../hooks';
import dayjs from 'dayjs';

const date = new Date();
const year = date.getFullYear();
const firstDay = new Date(year ,0 ,1)
const startOfYear = dayjs(firstDay)
const january = {
    month: "january",
    start: (startOfYear.startOf('month')).toDate().getTime(),
    end: (startOfYear.endOf('month')).toDate().getTime(),
};
const february = {
    month: "february",
    start: (startOfYear.add(1, 'month').startOf('month')).toDate().getTime(),
    end: (startOfYear.add(1, 'month').endOf('month')).toDate().getTime(),
};
const march = {
    month: "march",
    start: (startOfYear.add(2, 'month').startOf('month')).toDate().getTime(),
    end: (startOfYear.add(2, 'month').endOf('month')).toDate().getTime(),
};
const april = {
    month: "april",
    start: (startOfYear.add(3, 'month').startOf('month')).toDate().getTime(),
    end: (startOfYear.add(3, 'month').endOf('month')).toDate().getTime(),
};
const may = {
    month: "may",
    start: (startOfYear.add(4, 'month').startOf('month')).toDate().getTime(),
    end: (startOfYear.add(4, 'month').endOf('month')).toDate().getTime(),
};
const june = {
    month: "june",
    start: (startOfYear.add(5, 'month').startOf('month')).toDate().getTime(),
    end: (startOfYear.add(5, 'month').endOf('month')).toDate().getTime(),
};
const july = {
    month: "july",
    start: (startOfYear.add(6, 'month').startOf('month')).toDate().getTime(),
    end: (startOfYear.add(6, 'month').endOf('month')).toDate().getTime(),
};
const august = {
    month: "august",
    start: (startOfYear.add(7, 'month').startOf('month')).toDate().getTime(),
    end: (startOfYear.add(7, 'month').endOf('month')).toDate().getTime(),
};
const september = {
    month: "september",
    start: (startOfYear.add(8, 'month').startOf('month')).toDate().getTime(),
    end: (startOfYear.add(8, 'month').endOf('month')).toDate().getTime(),
};
const october = {
    month: "october",
    start: (startOfYear.add(9, 'month').startOf('month')).toDate().getTime(),
    end: (startOfYear.add(9, 'month').endOf('month')).toDate().getTime(),
};
const november = {
    month: "november",
    start: (startOfYear.add(10, 'month').startOf('month')).toDate().getTime(),
    end: (startOfYear.add(10, 'month').endOf('month')).toDate().getTime(),
};
const december = {
    month: "december",
    start: (startOfYear.add(11, 'month').startOf('month')).toDate().getTime(),
    end: (startOfYear.add(11, 'month').endOf('month')).toDate().getTime(),
};
const timestamp = [january, february, march, april, may, june, july, august, september, october, november, december];
const TableContent = ({stats}) => {
    const subsToDisplay = [];
    if (stats) {
        for (const plan in stats) {   
            subsToDisplay.push(
                <div className='tableRow'>
                    <p>{stats[plan].name}</p>
                    <p className='numbers'>{
                        stats[plan].planSubscribers["january"] !== 0 ? stats[plan].planSubscribers["january"] : null
                    }</p>
                    <p className='numbers'>{
                        stats[plan].planSubscribers["february"] !== 0 ? stats[plan].planSubscribers["february"] : null
                    }</p>
                    <p className='numbers'>{
                        stats[plan].planSubscribers["march"] !== 0 ? stats[plan].planSubscribers["march"] : null
                    }</p>
                    <p className='numbers'>{
                        stats[plan].planSubscribers["april"] !== 0 ? stats[plan].planSubscribers["april"] : null
                    }</p>
                    <p className='numbers'>{
                        stats[plan].planSubscribers["may"] !== 0 ? stats[plan].planSubscribers["may"] : null
                    }</p>
                    <p className='numbers'>{
                        stats[plan].planSubscribers["june"] !== 0 ? stats[plan].planSubscribers["june"] : null
                    }</p>
                    <p className='numbers'>{
                        stats[plan].planSubscribers["july"] !== 0 ? stats[plan].planSubscribers["july"] : null
                    }</p>
                    <p className='numbers'>{
                        stats[plan].planSubscribers["august"] !== 0 ? stats[plan].planSubscribers["august"] : null
                    }</p>
                    <p className='numbers'>{
                        stats[plan].planSubscribers["september"] !== 0 ? stats[plan].planSubscribers["september"] : null
                    }</p>
                    <p className='numbers'>{
                        stats[plan].planSubscribers["october"] !== 0 ? stats[plan].planSubscribers["october"] : null
                    }</p>
                    <p className='numbers'>{
                        stats[plan].planSubscribers["november"] !== 0 ? stats[plan].planSubscribers["november"] : null
                    }</p>
                    <p className='numbers'>{
                        stats[plan].planSubscribers["december"] !== 0 ? stats[plan].planSubscribers["december"] : null
                    }</p>
                </div>
            );
        };
    };
    return (
        <>{ subsToDisplay }</>
    );
}
export const GuildStats = ({service,spaceContent, spaceId}) => {
    const [ stats, setStats ] = useState({});
    const [ membersInService, setMembersInService ] = useState(0);
    const [ planInService, setPlanInService ] = useState(0);
    const [ eventInService, setEventInService] = useState(0);
    const [ totalEarnedValue, setTotalEarnedValue ] = useState(0);

    const getStats = async (spaceContent,service,timestamp) => {
        if (spaceContent[service]) {
            const serviceID = spaceContent[service].serviceID;
            const members = await _getMembersFromGuildService(serviceID);
            const plans = spaceContent[service].plans;
            const events = spaceContent[service].events;
            const subsStats = await _getSubsStats(service,serviceID,members,timestamp,plans);
            setStats(subsStats);
            let earned = 0;
            for (const month in subsStats.earned) {
                earned = earned + subsStats.earned[month];
            }
            earned = Math.trunc(earned * 0.975);
            setTotalEarnedValue(earned);
            if (members.length > 0) setMembersInService(members.length);
            if (plans.length > 0) setPlanInService(plans.length);
            if (events.length > 0) setEventInService(events.length);
        };
    };
    useEffect(() => {
        if (isEmpty(stats) || stats.service !== service) {
            getStats(spaceContent,service,timestamp);
        }
    }, [service, spaceContent, spaceId, stats]);

    return (
        <div className='GuildStats-wrapper'>
            <div className='stats-header'>
                <div className='members'>
                    <h2>{membersInService}</h2>
                    <h3>Members</h3>
                </div>
                <div className='plan'>
                <h2>{planInService}</h2>
                    <h3>Plans</h3>
                </div>
                { service !== 'telegram' ? 
                    <div className='event'>
                        <h2>{eventInService}</h2>
                        <h3>Events</h3>
                    </div> : null
                }
                <div className='earned'>
                    <h2>${totalEarnedValue}</h2>
                    <h3>Earned</h3>
                </div>
            </div>
            <div className='table'>
                <p>Subscribers by month</p>
                <p>January</p>
                <p>February</p>
                <p>March</p>
                <p>April</p>
                <p>May</p>
                <p>June</p>
                <p>July</p>
                <p>August</p>
                <p>September</p>
                <p>October</p>
                <p>November</p>
                <p>December</p>
            </div>
            <TableContent 
                stats={stats.stats}
            />
        </div>
    );
};