import React from 'react';
import './style.css';
import { useState } from 'react';
import { motion } from 'framer-motion';
import { responsive } from './responsive';

const questions = [
     {
        question: "What's Br0bot ?",
        answer: `Br0bot is a powerful automation tool that utilizes blockchain technology to manage Discord and Telegram communities. With Br0bot, server owners can create subscription plans and monetize their plans in cryptocurrency. The corresponding roles are automatically assigned to subscribers, making member access management effortless.\n\nIn addition to its membership management capabilities, Br0bot also offers a comprehensive event management system for Discord. You can easily create, schedule, and announce events to keep your community engaged and informed.\n\nBr0bot is the perfect solution for server owners looking to automate repetitive tasks and streamline their management process. It's ideal for various communities, including gaming, social networks, and business organizations.\n\nTry Br0bot today and experience how it simplifies the management of your Discord and Telegram communities at the same time as allowing you to monetize your passions.`,
     },
     {
        question: "What services are available ?",
        answer: `For the moment, Br0bot is available on Discord and Telegram, but we are not closed to expanding to other platform.`,
     },
     {
        question: "Is Br0bot free ?",
        answer: `We only take 2.5% on each transaction on our contract. On the other hand, creating a guild is completely free.`,
     },
     {
      question: "Discord bot permissions",
      answer: `Here is a list of the main permissions necessary for the bot to work \n\n- Manage Roles : This permission is used to assign and remove roles to your customer on your guild. \n- Manage channels : This permission is used to create channels, in particular the price-tracker channel. \n- Read messages / Read message history / Mention @ : This permission is used for the ticket system. \n- Manage events / Create events : This permission is used to create your events on your discord guild. \n- Connect / Speak : Music bot project`,
   }
];

const Questions = ({ question, answer }) => {
    const [showRep, setShowRep] = useState(false);

    const changeState = () => {
        setShowRep(!showRep);
    };

    return (
        <>
        <div className="question" onClick={changeState}>
            <h2>{question}</h2>
            <motion.p animate={{ rotate: showRep ? 135 : 0 }}>+</motion.p>
        </div>
        {showRep ? (
            <motion.div initial={{ y: -30 }} animate={{ y: 0 }} className="answer" onClick={changeState}>
                <p>{answer}</p>
            </motion.div>
        ) : null}
        </>
    );
};
export const Faq = ({ format }) => {
    const dynamicStyle = responsive[format];
  
    return (
      <div className="faq-wrapper">
        <div style={dynamicStyle.faq} className="faq">
          <h1>Frequently Asked Questions</h1>
          {
            questions.map((question, index) => (
                <Questions key={index} question={question.question} answer={question.answer} />
            ))
          }
        </div>
      </div>
    );
};