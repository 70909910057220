import { responsive } from './responsive';
import { useEffect, useState } from 'react';
import './style.css';

const br0bot = require('../../assets/images/logo.png');

export const GuildDetails = ({format, spaceContent, owner}) => {
    const [guildLogo, setguildLogo] = useState(br0bot);
    const [guildName, setGuildName] = useState("loading");
    const [guildDescription, setguildDescription] = useState("loading");
    const [guildLocation, setguildLocation] = useState("Metavers");
    const dynamicStyle = responsive[format];

    useEffect(() => {   
        if (spaceContent) {
            if (!spaceContent.logo === "logo") {
                setguildLogo(spaceContent.logo);
            }
            setGuildName(spaceContent.name.toUpperCase());
            setguildDescription(spaceContent.description);
            setguildLocation(spaceContent.continent.toUpperCase());
        }
    },[spaceContent]);

    return(
        <div className="guildDetails-container">
            <div style={dynamicStyle.guild} className="guildDetails-guild">
                <div style={dynamicStyle.left} className='leftContent'>
                    <img style={dynamicStyle.img} src={guildLogo} alt='Guild Logo' />
                </div>
                <div style={dynamicStyle.right} className='rightContent'>
                    <h2 style={dynamicStyle.h2}>[{guildLocation}] {guildName}</h2>
                    <p style={dynamicStyle.desc}>{guildDescription}</p>
                    { owner ? 
                    <p className='admin'>Administrator</p>
                    :
                    null
                    }
                </div>
            </div>
        </div>
    );
};
