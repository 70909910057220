import './style.css';

const warningLogo = require(`../../assets/images/warning.png`);

export const HowToUse = () => {
    return (
        <div className='howtouse-container'>
            <div className='howtouse'>
                <div className='left-part'>
                    <img className='footerlogo' src={warningLogo} alt='Br0bot logo'/>
                </div>
                <div className='right-part'>
                    <h2>How to use:</h2>
                    <ul>
                        <li>1. Create a new telegram group, or kick all the member from your group. (All members who have not joined through br0bot will not be taken into account by it)</li>
                        <li>2. Invit br0bot on your telegram group.</li>
                        <li>3. Create an invitation to the group with admin approval, br0bot will handle accepting invitations from members who have subscribed to a plan.</li>
                        <li>4. Share the invitation link with your members.</li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default HowToUse;