import { checkProvider } from '../../hooks';
import { responsive } from './responsive';
import './style.css';
import { Buttons } from '../../fragments';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { toast } from 'react-toastify';

const logo = require('../../assets/images/logo.png');
const avax = require('../../assets/images/avax.png');
const discord = require('../../assets/images/discord.png');
const twitter = require('../../assets/images/twitter.png');

const onCreateGuild = (navigate) => {
    checkProvider().then((provider) => {
        if (provider.account !== "noprovider" && provider.chain) {
            window.open('https://br0bot.mynotice.io/', 'myWindow', 'width=500,height=500');
            navigate(`/space/new`);
        } else {
            toast.info("You need to connect / install Metamask to create guilds", { position: toast.POSITION.BOTTOM_LEFT });
        }
    });
};

export const Header = ({format}) => {
    const dynamicStyle = responsive[format];
    const navigate = useNavigate();
    return(
        <div className='header'>
            <div style={dynamicStyle.leftRow} className='home-leftRow'>
                <div className='home-content-area'>
                    <h1 style={dynamicStyle.title}>Hi, i am <span style={{color:'#00ffff'}}>Br0bot</span>, your Decentralized Membership Programs compagnon for Discord & Telegram !</h1>
                    <div className='home-buttons-area'>
                        <Buttons 
                            borderColor='#00ffff'
                            backgroundColor='#07073d'
                            height={dynamicStyle.button.height}
                            width={dynamicStyle.button.width}
                            colorText='#00ffff'
                            text='Create Guild'
                            link={() => onCreateGuild(navigate)}
                            marginRight={dynamicStyle.button.marginRight}
                            fontSize={dynamicStyle.button.fontSize}
                        />
                       
                    </div>
                    <div className='home-powered'>
                        <div className='avax'>
                            <img src={avax} alt='Avalanche Network Logo' />
                        </div>
                        <p className='powered-text'>
                            Powered by <span style={{color:'#e84142'}}>Avalanche</span>
                        </p>
                    </div>
                    <div className='home-socials'>
                        <p className='socials-text'>
                            Stay tuned, follow us: 
                        </p>
                        <div className='discord'>
                            <a href='https://discord.gg/nw6EwTUKA7' alt='Discord link'>
                                <img src={discord} alt='Discord Br0bot'/>
                            </a>
                        </div>
                        <div className='twitter'>
                            <a href='https://twitter.com/Br0bot_off' alt='Twitter link'>
                                <img src={twitter} alt='Twitter Br0bot' />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div style={dynamicStyle.rightRow} className='home-rightRow'>
                <motion.div initial={{x:200}} animate={{x:-0}} transition={{duration: 1}} className='home-logo-area'>
                    <img className='home-logo-img' src={logo} alt='Logo Br0bot' />
                </motion.div>
            </div>
        </div>
    );
};