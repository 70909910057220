export const responsive = {
    'small': { //375x667
        customerBar: {
            width: '90%',
        },
        buttons: {
            width: '100%'
        },
        text: {
            display: 'none',
        },
        inputs: {
            display: 'none',
        }
    },
    'medium': { //768
        customerBar: {
            width: '90%',
            display: 'flex',
            flexDirection: 'column',
        },
        buttons: {
            width: '100%',
            marginBottom: '2%',
            marginTop: '2%',
        },
        text: {
            width: '100%',
            marginBottom: '2%',
        },
        inputs: {
            width: '100%',
            marginBottom: '2%',
        }
    },
    'large': {  //1224
        customerBar: {
            with: '80%'
        },
        buttons: {
            width: '30%',
        },
        text: {
            width: '40%',
            paddingRight: '3%',
            paddingLeft: '3%',
        },
        inputs: {
            width: '30%',
        },
        p: {
            fontSize: '12px',
        },
    },
    'x-large': { //1920
    },
    'xx-large': { //2465
    },
    'xxx-large': {
    }
};