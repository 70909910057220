import './style.css';
import forEach from 'lodash/forEach';
import { useState } from 'react';
import { Buttons } from '../../fragments';
import { responsive } from './responsive';
import { motion } from 'framer-motion';

const stories = {
    telegram: {
        name: 'Telegram',
        stories: [
            {
                img:'telegram.png',
                title:'Telegram Limitations',
                description:'Due to the limitations of the Telegram API (the programming interface that allows software to communicate with Telegram), it is not possible to offer the same features on both Telegram and Discord.'
            },
            {
                img:'logo.png',
                title:'Automate group management',
                description:'Create subscriptions for your users and focus on the content of your group, Br0bot handles the members for you.'
            },
        ]
    },
    discord: {
        name: 'Discord',
        stories: [
            {
                img:'features.jpg',
                title:'Many functionalities',
                description:'Br0bot takes care of managing your community member subscriptions, providing guidance and information, and allowing you to effortlessly add or remove members. It also ensures that you receive the fruits of your labor in a timely and efficient manner.'
            },
            {
                img:'plans_management.jpg',
                title:'Bring to life your memberships!',
                description:`You have the freedom to create plans that suit your needs, whether they are daily, monthly, or yearly. With the ability to set your own price and assign roles, the process is straightforward and simple.`
            },
            {
                img:'account_management.jpg',
                title:'Active subscriptions!',
                description:`Br0bot provides visibility into your members' active subscriptions and the duration remaining on them. As the subscription approaches its end date, Br0bot will notify the user to ensure they have ample time to renew their subscription.`
            },
            {
                img:'event_discord.jpg',
                title:'Monetize Events!',
                description:'Engage with your community with timed events, we help you promote it.'
            },
            {
                img:'logo.png',
                title:`So, what are you waiting for? `,
                description:`Create your guild now in less than 15 minuts ! I promise, you won't regret it.`
            }
        ]
    }
};

const isOdd = (n) => {
    return Math.abs(n)%2===1;
};

const Stories = ({ format, story, dynamicStyle }) => {
    const storiesContent = [];
    forEach(story.stories, (epic, key) => {
        const image = require(`../../assets/images/${epic.img}`);
        storiesContent.push(
            <div key={`story-${story.name}-${key}`} className='story'>
                { format === 'small' || isOdd(key+1) ? (
                    <>
                        <div className='storyline-picture' style={{...dynamicStyle.storyCardImage, ...dynamicStyle.storyCard, ...dynamicStyle.storyCardContentLeft}}>
                            <img className='story-image' src={image} alt={epic.title} />
                        </div>
                        <motion.div initial={{x:50}} whileInView={{x:-0}} transition={{duration: 1}} className='storyline' style={{...dynamicStyle.storyCard, ...dynamicStyle.storyCardContentRight}}>
                            <h4 style={{...dynamicStyle.title, ...dynamicStyle.textRight}}>{epic.title}</h4>
                            <p style={dynamicStyle.textRight}>{epic.description}</p>
                        </motion.div>
                    </>
                ) : ( 
                    <>
                        <motion.div initial={{x:-50}} whileInView={{x:-0}} transition={{duration: 1}} className='storyline' style={{...dynamicStyle.storyCard, ...dynamicStyle.storyCardContentLeft}}>
                            <h4 style={{...dynamicStyle.title, ...dynamicStyle.textLeft}}>{epic.title}</h4>
                            <p style={dynamicStyle.textLeft}>{epic.description}</p>
                        </motion.div>
                        <div className='storyline-picture' style={{...dynamicStyle.storyCard, ...dynamicStyle.storyCardContentRight}}>
                            <img className='story-image' src={image} alt={epic.title} />
                        </div>
                    </>
                )}
            </div>
        );
    });

    return(
        <div style={dynamicStyle.storiesContainer} className='stories-container'>
            {storiesContent}
        </div>
    )
};

export const Storyline = ({format}) => {
    const dynamicStyle = responsive[format];
    const [story, setStory] = useState(stories.discord);
    return(
        <div className='stories'>
            <div style={dynamicStyle.storiesSelect} className='stories-select'>
                <Buttons 
                    borderColor={story.name === stories.discord.name ? '#00ffff' : '#ffffff'}
                    backgroundColor='linear-gradient(276deg, rgba(51,102,153,1) 100%, rgba(0,212,255,1) 100%)'
                    height={'60px'}
                    width={'180px'}
                    colorText={'#ffffff'}
                    text={stories.discord.name}
                    link={() => setStory(stories.discord)}
                    marginRight={'10px'}
                    fontSize={'1rem'}
                />
                <Buttons 
                    borderColor={story.name === stories.telegram.name ? '#00ffff' : '#ffffff'}
                    backgroundColor='linear-gradient(276deg, rgba(51,102,153,1) 100%, rgba(0,212,255,1) 100%)'
                    height={'60px'}
                    width={'180px'}
                    colorText={'#ffffff'}
                    text={stories.telegram.name}
                    link={() => setStory(stories.telegram)}
                    marginRight={'0px'}
                    fontSize={'1rem'}
                />
            </div>
            <Stories format={format} story={story} dynamicStyle={dynamicStyle} />
        </div>
    );
};