export const responsive = {
    'small': {
        faq: {
            width: '80%',
        },
    },
    'medium': {
        faq: {
            width: '60%',
        },
    },
    'large': {
        faq: {
            width: '60%',
        },
    },
    'x-large': {
        faq: {
            width: '60%',
        },
    },
    'xx-large': {
        faq: {
            width: '60%',
        },
    },
    'xxx-large': {
        faq: {
            width: '60%',
        },
    }
};